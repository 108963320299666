import * as React from 'react';

const SvgJobs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.476 6.133h-4.785V5.6c0-.882-.71-1.6-1.582-1.6H9.891c-.873 0-1.582.718-1.582 1.6v.533H3.527A.532.532 0 003 6.667V18.4c0 .882.71 1.6 1.582 1.6h14.836C20.29 20 21 19.282 21 18.4V6.678v-.002c-.02-.35-.233-.54-.524-.543zM9.363 5.6c0-.294.237-.533.528-.533h4.218c.291 0 .528.239.528.533v.533H9.363V5.6zm10.378 1.6l-1.638 4.969a.527.527 0 01-.5.364h-2.966V12a.53.53 0 00-.528-.533H9.891a.53.53 0 00-.528.533v.533H6.397a.527.527 0 01-.5-.364L4.259 7.2h15.482zm-6.159 5.333v2.134h-3.164v-2.134h3.164zm6.363 5.867a.531.531 0 01-.527.533H4.582a.531.531 0 01-.527-.533V9.953l.841 2.553a1.581 1.581 0 001.5 1.094h2.967v1.6a.53.53 0 00.528.533h4.218a.53.53 0 00.528-.533v-1.6h2.966c.682 0 1.285-.44 1.5-1.094l.842-2.553V18.4z"
        fill="#363636"
        stroke="#363636"
        strokeWidth={0.5}
      />
    </svg>
  );
};

const MemoSvgJobs = React.memo(SvgJobs);
export default MemoSvgJobs;
