import * as React from 'react';

const SvgProperty = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.703 11.267a.25.25 0 01.024-.397L6.5 9.014a6.75 6.75 0 002.434-2.909l1.081-2.476a.25.25 0 01.393-.09l5.054 4.353a.25.25 0 01.027.351l-1.201 1.425a1.75 1.75 0 00.304 2.54l5.213 3.82a.25.25 0 01.053.351l-1.246 1.662a.25.25 0 01-.352.048l-5.43-4.153a1.75 1.75 0 00-2.365.221L9.144 15.63a.25.25 0 01-.35.022l-5.091-4.385zm-.81-1.645a1.75 1.75 0 00-.17 2.781l5.093 4.386a1.75 1.75 0 002.443-.157l1.322-1.471a.25.25 0 01.338-.032l5.43 4.153a1.75 1.75 0 002.463-.34l1.246-1.662a1.75 1.75 0 00-.366-2.462l-5.214-3.819a.25.25 0 01-.044-.363l1.202-1.425a1.75 1.75 0 00-.196-2.454l-5.054-4.353a1.75 1.75 0 00-2.745.625L7.559 5.505a5.25 5.25 0 01-1.893 2.263L2.893 9.622zm1.143 8.497a1 1 0 100 2h7.014a1 1 0 100-2H4.036z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgProperty = React.memo(SvgProperty);
export default MemoSvgProperty;
