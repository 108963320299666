import * as React from 'react';

const SvgEngineCapacity = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 29 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M22.382 6.173l.048.002.158.018c.398.08.676.386.727.779l.009.134-.003 12.165-.018.158a.898.898 0 01-.787.716l-.134.009H9.84l-3.913-3.838V10.5l4.41-.001.001-4.326h12.044z"
          fill="#FFF"
        />
        <path
          d="M23.632 8.673l.048.002.158.018c.398.08.676.386.727.779l.009.134-.003 10.915-.018.158a.898.898 0 01-.787.716l-.134.009H11.09l-2.663-2.588V13l4.41-.001.001-4.326h10.794z"
          fill="#8DCED6"
        />
        <path
          d="M11.827.249L22.382.25l.123.009c.398.057.701.39.701.79a.807.807 0 01-.71.79l-.147.008h-4.437v2.73h4.47l.203.008c1.272.093 2.275 1.062 2.377 2.311l.009.23v5.24h1.882v-5.26l.009-.118c.058-.383.399-.68.814-.68.457 0 .824.36.824.798v11.25l-.009.117a.816.816 0 01-.815.68.811.811 0 01-.823-.797v-4.394H24.97v5.26l-.007.197c-.096 1.24-1.083 2.222-2.364 2.323l-.236.008H9.19l-4.911-4.816v-2.972H2.398v4.394l-.009.117a.816.816 0 01-.814.68.816.816 0 01-.817-.69l-.008-.142L.75 7.106l.009-.118c.058-.383.4-.68.815-.68.415 0 .762.3.816.69l.008.142v5.226H4.28l-.001-3.462H8.69V4.576l7.574.001v-2.73h-4.47l-.123-.01a.807.807 0 01-.701-.789c0-.398.304-.736.71-.79l.146-.01zm10.555 5.924H10.338v4.326H5.927v5.817l3.913 3.838h12.542l.134-.009a.898.898 0 00.787-.716l.018-.158.003-12.165-.009-.134a.897.897 0 00-.727-.779l-.158-.018-.048-.002z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgEngineCapacity = React.memo(SvgEngineCapacity);
export default MemoSvgEngineCapacity;
