export const minPriceLimit = 8000;
export const maxPriceLimit = 400000;

export const minYearLimit = 1990;
export const maxYearLimit = new Date().getFullYear();

export const fields = {
  FAMILY_TYPE: 'carFamilyType',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  MIN_YEAR: 'min-year',
  MAX_YEAR: 'max-year',
  MIN_PRICE: 'min-price',
  MAX_PRICE: 'max-price',
  SUB_MODEL: 'subModel',
  YEAR: 'year',
  ENGINE_TYPE: 'engine-type',
  SEATS: 'seats',
  DOORS: 'doors',
  PAGE: 'page',
} as const;

export type FieldKeysEnum = keyof typeof fields;
export type FieldEnums = (typeof fields)[FieldKeysEnum];

export const serverFields = {
  FAMILY_TYPE: 'family-type',
  MANUFACTURER: 'manufacturer',
  MODEL: 'model',
  MIN_YEAR: 'min-year',
  MAX_YEAR: 'max-year',
  MIN_PRICE: 'min-price',
  MAX_PRICE: 'max-price',
  ENGINE_TYPE: 'engine-type',
  SEATS: 'seats',
  DOORS: 'doors',
  PAGE: 'page',
} as const;

export type ServerFieldKeysEnum = keyof typeof fields;
export type ServerFieldEnums = (typeof fields)[ServerFieldKeysEnum];
