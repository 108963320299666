import * as React from 'react';

const SvgSort = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M17.93 9.878a1.297 1.297 0 11-1.835-1.835l3.665-3.665c.254-.254.586-.381.918-.378.332 0 .664.126.92.382l3.662 3.662a1.297 1.297 0 11-1.835 1.835L21.95 8.428v16.309c0 .717-.557 1.298-1.273 1.298-.721 0-1.321-.581-1.321-1.298l.024-16.309-1.451 1.451zm-6.356-3.302c0-.722-.557-1.298-1.274-1.298-.721 0-1.321.581-1.321 1.298V22.93l-1.427-1.451a1.297 1.297 0 10-1.835 1.835l3.665 3.665c.254.254.586.381.918.378.332 0 .664-.126.92-.382l3.662-3.662a1.297 1.297 0 10-1.835-1.835l-1.451 1.451-.024-16.354z" />
    </svg>
  );
};

const MemoSvgSort = React.memo(SvgSort);
export default MemoSvgSort;
