import * as React from 'react';

const SvgNotificationSuccess = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#0FCA80" cx={12} cy={12} r={12} />
        <path
          d="M7.137 12.245l2.965 3.38a1.104 1.104 0 001.646.014l6.109-6.718a.55.55 0 10-.817-.74l-6.108 6.718-2.966-3.38a.552.552 0 00-.83.726z"
          fill="#DFF7ED"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

const MemoSvgNotificationSuccess = React.memo(SvgNotificationSuccess);
export default MemoSvgNotificationSuccess;
