import * as React from 'react';

const SvgHome = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M4.204 11.495l-.06.065a.667.667 0 00.524 1.08h1.654v5.215c0 .368.299.667.667.667h2.827l.084-.005a.667.667 0 00.583-.662v-3.24h2.503v3.24c0 .368.298.667.667.667h2.628l.084-.005a.667.667 0 00.583-.662V12.64h1.72a.667.667 0 00.464-1.145l-6.153-5.964a1.884 1.884 0 00-2.622 0l-6.153 5.964zm12.817-.188h-.74l-.083.006a.667.667 0 00-.583.661l-.001 5.215h-1.295v-3.24a.667.667 0 00-.666-.666H9.816l-.083.005a.667.667 0 00-.583.661v3.24H7.656v-5.215l-.006-.084a.667.667 0 00-.661-.583h-.675l4.97-4.818a.55.55 0 01.767 0l4.97 4.818z"
        fill="#CCC"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgHome = React.memo(SvgHome);
export default MemoSvgHome;
