import * as React from 'react';

const SvgFamilyhover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(8 11)" fill="none" fillRule="evenodd">
        <path
          d="M4.946 15.015H2.893a2.053 2.053 0 01-2.054-2.053V8.847a2.4 2.4 0 012.042-2.373l7.53-1.138a2.4 2.4 0 01.985.056l6.007 1.621a2.4 2.4 0 00.625.083h23.903a2.4 2.4 0 012.33 2.978l-.774 3.12a2.4 2.4 0 01-2.33 1.821H4.946z"
          fill="#FFAB00"
        />
        <ellipse fill="#FFF" cx={9.573} cy={14.4} rx={3.625} ry={3.6} />
        <ellipse fill="#FFF" cx={32.177} cy={14.4} rx={3.625} ry={3.6} />
        <g stroke="#000" strokeWidth={1.2}>
          <path d="M10.91 5.042l4.947 1.527a4.8 4.8 0 001.415.213h21.856" />
          <path strokeLinecap="square" d="M24.831 1.027L24.389 13.8" />
          <path d="M39.84 7.096c.049.728.274 1.444.673 2.148.4.705 1.62 1.05 3.661 1.035" />
          <path
            d="M40.602 15.015h.867c1.108 0 2.07-.771 2.318-1.86l.47-1.609c.208-.908.477-3.5 0-4.298-.317-.533-2.058-.726-5.224-.58-1.29-2.167-3.98-6.1-6.486-6.1h-11.35c-1.297 0-2.57.357-3.681 1.032L10.91 5.042l-7.66.692C1.41 5.901 0 7.456 0 9.32v3.295a2.39 2.39 0 002.38 2.4h3.479m10.429 0h12.235"
            strokeLinecap="round"
          />
          <path d="M31.784 0l1.527 6.782h-.151" />
          <ellipse
            strokeLinejoin="round"
            cx={9.409}
            cy={14.369}
            rx={3.625}
            ry={3.6}
          />
          <ellipse
            strokeLinejoin="round"
            cx={32.651}
            cy={14.369}
            rx={3.625}
            ry={3.6}
          />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.019 9.823h2.38M.84 9.31l4.05-1.07a1.2 1.2 0 00.894-1.16v-.941h0"
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgFamilyhover = React.memo(SvgFamilyhover);
export default MemoSvgFamilyhover;
