import * as React from 'react';

const SvgMinivanhover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFAB00"
          d="M8.035 26.951h38.688L52 24.567l-.332-2.535-2.328-.91-.445-2.517H23.416l-7.536-1.226L8.035 20.5 7 26.016z"
        />
        <g transform="translate(7 11)">
          <ellipse fill="#FFF" cx={9.649} cy={15.323} rx={3.653} ry={3.677} />
          <ellipse fill="#FFF" cx={32.431} cy={15.323} rx={3.653} ry={3.677} />
          <g stroke="#000" strokeWidth={1.2}>
            <path d="M8.88 6.679l7.35.607a4.8 4.8 0 00.458.016l25.207-.325h0" />
            <path
              strokeLinecap="square"
              d="M23.943.946l-.558 5.938-.003 8.439"
            />
            <path d="M43.364 6.018c-1.233.313-1.69 1.17-1.371 2.57.32 1.401 1.21 2.216 2.675 2.444" />
            <path
              d="M39.723 15.951h.874c1.117 0 4.152-1.271 4.403-2.384l-.092-1.732a3.748 3.748 0 00-.422-2.72l-.98-4.935c-.639-1.086-1.266-2.113-2.037-2.877-.191-.19 1.199-.528.987-.682C41.783.13 39.405 0 38.426 0H21.252c-1.308 0-4.892 1.226-6.109 2.28L8.615 6.884 3.212 8.589C1.392 9.115 0 10.68 0 12.585v.915c0 1.354 1.074 2.451 2.398 2.451h3.507m10.511 0h12.332"
              strokeLinecap="round"
            />
            <path d="M33.369.282l1.618 6.71h-.152" />
            <ellipse
              strokeLinejoin="round"
              cx={9.483}
              cy={15.291}
              rx={3.653}
              ry={3.677}
            />
            <ellipse
              strokeLinejoin="round"
              cx={32.909}
              cy={15.291}
              rx={3.653}
              ry={3.677}
            />
          </g>
        </g>
        <path
          stroke="#000"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M24.97 21.647h2.398"
        />
      </g>
    </svg>
  );
};

const MemoSvgMinivanhover = React.memo(SvgMinivanhover);
export default MemoSvgMinivanhover;
