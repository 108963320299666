import * as React from 'react';

const SvgHomeGarden = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M858 956H652V751H371v205H165V544l346-241 347 241v412zM512 258L128 525v467h281V788h206v204h280V525l-7-6-376-261zm302-158q-14-13-29-26.5T758 43l-8-11-12 6q-48 21-77.5 54T625 164q-5 31 11 78t83 104l1 1 23-25-2-1q-46-39-67-77.5T659 169q4-28 25-52.5T740 74q12 15 25.5 27.5T792 126q30 25 51.5 51.5T862 246q-2 22-21.5 43.5T791 317q3-38-6-82.5T756 141l-1-2-32 13 1 1q27 66 32.5 121.5T745 372l-28 61 32 12 28-61q3-8 5.5-16t4.5-16q42-5 74-35t35-68q3-56-23.5-88.5T814 100z" />
    </svg>
  );
};

const MemoSvgHomeGarden = React.memo(SvgHomeGarden);
export default MemoSvgHomeGarden;
