import * as React from 'react';

const SvgRealestate = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.14 3.505l-.168-.005-.163.01c-.43.042-.834.231-1.14.538l-6.621 6.619-.104.113c-.46.543-.567 1.3-.3 1.942l.062.134.072.128c.334.54.934.896 1.605.896h.324v1.423l-.002.1.003.037v3.178l.005.14A1.89 1.89 0 007.59 20.5h8.822l.14-.005.137-.015a1.891 1.891 0 001.606-1.862v-4.739h.324l.154-.005a1.887 1.887 0 001.585-1.152 1.89 1.89 0 00-.404-2.055l-6.62-6.62-.124-.113a1.882 1.882 0 00-1.07-.43zm-.146 1.552l-.06.008a.322.322 0 00-.112.048l-.048.04-6.62 6.62c-.107.108-.124.214-.068.349.047.113.116.176.226.194l.071.006h1.104a.781.781 0 01.772.681l.006.098v2.208l.002.028-.002.092v3.189c0 .161.107.288.257.318l.067.006h1.424v-3.633c0-.987.775-1.807 1.745-1.88l.14-.004h2.206c.987 0 1.806.774 1.879 1.744l.005.14v3.633h1.424a.316.316 0 00.317-.257l.007-.067V13.1a.782.782 0 01.681-.772l.098-.006h1.104c.152 0 .24-.064.296-.2.047-.113.043-.205-.021-.295l-.046-.053-6.621-6.62a.325.325 0 00-.169-.09l-.064-.007zm1.232 9.93h-2.45c-.198 0-.343.112-.376.272l-.007.072.007 3.61h3.216l-.007-3.61c0-.173-.12-.307-.302-.338l-.08-.007z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgRealestate = React.memo(SvgRealestate);
export default MemoSvgRealestate;
