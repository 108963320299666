import * as React from 'react';

const SvgExclamationMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 28C9.373 28 4 22.627 4 16S9.373 4 16 4s12 5.373 12 12-5.373 12-12 12zm0-2.4c5.302 0 9.6-4.298 9.6-9.6S21.302 6.4 16 6.4 6.4 10.698 6.4 16s4.298 9.6 9.6 9.6z" />
      <path d="M14.667 10.667V18a1.333 1.333 0 002.666 0v-7.333a1.333 1.333 0 00-2.666 0zM17.333 21.333a1.333 1.333 0 11-2.667 0 1.333 1.333 0 012.667 0z" />
    </svg>
  );
};

const MemoSvgExclamationMark = React.memo(SvgExclamationMark);
export default MemoSvgExclamationMark;
