import * as React from 'react';

const SvgEdit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M19.454 6.154a.706.706 0 00-.965.259l-8.27 14.324a.448.448 0 00-.058.253l.318 4.248a.44.44 0 00.629.364l3.838-1.848c.08-.038.146-.1.19-.176l8.403-14.555a.44.44 0 00-.161-.601l-3.926-2.267zm.98-1.697l3.926 2.267a2.4 2.4 0 01.878 3.278l-8.403 14.555a2.398 2.398 0 01-1.037.962l-3.838 1.848a2.4 2.4 0 01-3.435-1.983l-.318-4.248a2.4 2.4 0 01.315-1.379l8.27-14.324a2.667 2.667 0 013.643-.976z" />
      <path d="M15.833 10.364l1.066-1.847 6.097 3.52-1.066 1.847-6.097-3.52z" />
    </svg>
  );
};

const MemoSvgEdit = React.memo(SvgEdit);
export default MemoSvgEdit;
