import * as React from 'react';

const SvgSideMenuAccessibility = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M26.588 7.225c.715-.027 1.321.489 1.345 1.159.028.665-.524 1.232-1.24 1.26l-7.553.887.003 7.023 3.66 11.347c.193.717-.271 1.433-1.033 1.611s-1.543-.213-1.735-.929l-3.749-10.329h-1.155l-3.449 10.543c-.288.688-1.113 1.005-1.853.737-.732-.267-1.18-1.04-.891-1.728l3.168-11.136v-7.139l-6.96-.879c-.713-.053-1.199-.637-1.14-1.301.052-.668.701-1.165 1.415-1.111l8.456.676h3.708l9.004-.689zM15.961 1.373c1.657 0 2.997 1.311 2.997 2.92 0 1.62-1.34 2.931-2.997 2.931-1.644 0-2.985-1.311-2.985-2.931 0-1.609 1.34-2.92 2.985-2.92z" />
    </svg>
  );
};

const MemoSvgSideMenuAccessibility = React.memo(SvgSideMenuAccessibility);
export default MemoSvgSideMenuAccessibility;
