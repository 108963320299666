import * as React from 'react';

const SvgProfileCredit = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M23.741 8.889c1.123 0 2.037.931 2.037 2.073v9.123c0 1.143-.914 2.073-2.037 2.073H8.259c-1.123 0-2.037-.931-2.037-2.073v-9.123c0-1.143.914-2.073 2.037-2.073zm1.222 5.587H7.037l.001 5.61c0 .646.486 1.178 1.105 1.238l.118.006h15.482c.674 0 1.222-.558 1.222-1.244v-5.61zm-2.59 2.095c.722 0 1.31.588 1.31 1.31v.873c0 .722-.588 1.31-1.31 1.31H21.5c-.722 0-1.31-.588-1.31-1.31v-.873c0-.722.588-1.31 1.31-1.31zm-8.169 2.096c.22 0 .399.156.399.349s-.179.349-.399.349H9.415c-.22 0-.399-.156-.399-.349s.179-.349.399-.349zm8.169-1.223H21.5a.437.437 0 00-.437.437v.873c0 .241.196.437.437.437h.873a.437.437 0 00.437-.437v-.873a.437.437 0 00-.437-.437zm-8.169-.174c.22 0 .399.156.399.349s-.179.349-.399.349H9.415c-.22 0-.399-.156-.399-.349s.179-.349.399-.349zm10.759-5.413H7.037v1.746h17.926v-1.746zm-1.222-2.139H8.259c-.674 0-1.222.558-1.222 1.244l-.001.022h17.926v-.022c0-.646-.486-1.178-1.105-1.238l-.118-.006z" />
    </svg>
  );
};

const MemoSvgProfileCredit = React.memo(SvgProfileCredit);
export default MemoSvgProfileCredit;
