import * as React from 'react';

const SvgStopwatch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={'35px'}
      width={'35px'}
      viewBox="0 0 32 32"
      {...props}
    >
      <title>stopwatch icon</title>
      <g
        id="icon_stopwatch"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="Group-4"
          transform="translate(3.000000, 2.000000)"
          fill-rule="nonzero"
        >
          <g id="stopwatch" transform="translate(0.272000, 0.216000)">
            <path
              d="M19.8785664,2.6125902 L22.7817652,5.03363322 C22.9816521,5.20037496 23.2244674,5.28174108 23.4659462,5.28174108 C23.7722186,5.28174108 24.0761921,5.150871 24.2876799,4.89731022 C24.6658559,4.44380904 24.6048046,3.76957152 24.15125,3.39139548 L21.2480512,0.970299 C20.794657,0.59212296 20.120366,0.65312082 19.7421365,1.10667546 C19.3639604,1.56017664 19.4250118,2.2343607 19.8785664,2.6125902 Z"
              id="Path"
              fill="#FF7100"
            ></path>
            <path
              d="M1.76722722,5.28168762 C2.00865258,5.28168762 2.25152136,5.2003215 2.4514083,5.03357976 L5.3545536,2.6125902 C5.80805478,2.23441416 5.86915956,1.56017664 5.49098352,1.10667546 C5.11286094,0.65322774 4.43856996,0.59212296 3.98506878,0.970299 L1.08187002,3.39134202 C0.62836884,3.76951806 0.56726406,4.44375558 0.9454401,4.89725676 C1.15698132,5.15081754 1.46095488,5.28168762 1.76722722,5.28168762 Z"
              id="Path"
              fill="#FF7100"
            ></path>
            <ellipse
              id="Oval"
              fill="#FFFFFF"
              cx="12.640863"
              cy="14.754191"
              rx="11.5516328"
              ry="11.4988053"
            ></ellipse>
            <path
              d="M12.640863,2.37538567 C5.77596249,2.37538567 0.209230235,7.91666034 0.209230235,14.754191 C0.209230235,21.5917216 5.77596249,27.1329962 12.640863,27.1329962 C19.5057636,27.1329962 25.0724959,21.5917216 25.0724959,14.754191 C25.0724959,7.91666034 19.5057636,2.37538567 12.640863,2.37538567 Z M12.640863,4.13538567 C18.5355438,4.13538567 23.3124959,8.89049198 23.3124959,14.754191 C23.3124959,20.6178899 18.5355438,25.3729962 12.640863,25.3729962 C6.74618233,25.3729962 1.96923023,20.6178899 1.96923023,14.754191 C1.96923023,8.89049198 6.74618233,4.13538567 12.640863,4.13538567 Z"
              id="Oval-Copy-3"
              fill="#FF7100"
            ></path>
            <path
              d="M12.6175292,0 C13.2147493,-1.09707535e-16 13.6988917,0.484142473 13.6988917,1.08136248 L13.6988917,3.52 L13.6988917,3.52 L11.5361668,3.52 L11.5361668,1.08136248 C11.5361668,0.484142473 12.0203092,1.09707535e-16 12.6175292,0 Z"
              id="Rectangle"
              fill="#FF7100"
            ></path>
            <path
              d="M17.8253816,8.31842946 C17.366481,7.94704284 16.6932058,8.01787734 16.3216588,8.47693836 L12.9504177,12.6428089 C12.8415731,12.6256482 12.730109,12.61656 12.61656,12.61656 C11.4374462,12.61656 10.47816,13.5758462 10.47816,14.75496 C10.47816,15.9340738 11.4374462,16.89336 12.61656,16.89336 C13.7956738,16.89336 14.75496,15.9340738 14.75496,14.75496 C14.75496,14.4848801 14.7041195,14.2265614 14.6123287,13.9884505 L17.9838905,9.82215234 C18.3553841,9.36309132 18.2844427,8.68981608 17.8253816,8.31842946 Z"
              id="Path"
              fill="#FF7100"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgStopwatch = React.memo(SvgStopwatch);
export default MemoSvgStopwatch;
