import * as React from 'react';

const SvgStarFull = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 10 9"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M.79 3.216l2.652-.398a.441.441 0 00.33-.241L4.689.712a.441.441 0 01.79-.004l.945 1.873a.441.441 0 00.328.237l2.625.397a.441.441 0 01.224.769L7.705 5.638a.441.441 0 00-.147.397l.328 2.213a.441.441 0 01-.633.46L5.33 7.75a.441.441 0 00-.386-.003l-2.053.977a.441.441 0 01-.626-.466l.346-2.222a.441.441 0 00-.147-.4L.565 3.985a.441.441 0 01.224-.769z"
        fill="#FF7100"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgStarFull = React.memo(SvgStarFull);
export default MemoSvgStarFull;
