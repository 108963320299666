import * as React from 'react';

const SvgPhone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M14.755 10.565l-1.21 1.21a1.717 1.717 0 00.004 2.428l4.033 4.033a1.713 1.713 0 002.428.004l1.21-1.21a1.144 1.144 0 011.619-.001l4.443 4.443c.67.67.673 1.753-.003 2.429l-2.423 2.423c-1.561 1.561-4.394 1.971-6.289.837 0 0-3.035-1.277-7.822-6.064s-6.111-7.878-6.111-7.878c-1.104-1.913-.729-4.733.827-6.29l2.423-2.423a1.714 1.714 0 012.429-.003l4.443 4.443c.447.447.448 1.17-.001 1.619zm-7.677-2.02c-.827.827-.967 2.481-.464 3.531 0 0 .807 1.465 1.173 2.004 1.08 1.594 2.582 3.408 4.575 5.4s3.795 3.485 5.37 4.55c.532.36 2.009 1.169 2.009 1.169.957.501 2.658.349 3.499-.492l2.023-2.023-3.232-3.232-.404.404a3.998 3.998 0 01-5.66-.004l-4.033-4.033a4.002 4.002 0 01-.004-5.66l.404-.404-3.232-3.232L7.08 8.545z" />
    </svg>
  );
};

const MemoSvgPhone = React.memo(SvgPhone);
export default MemoSvgPhone;
