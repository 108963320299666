import * as React from 'react';

const SvgLaxuryhover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.47 26c.628 0 15.253-.029 43.877-.087L54 23.56v-4.705l-8.325-2.66L40.035 13l-1.814 2.62H21.619L7.956 18.857 4.47 20.62c-.627 3.587-.627 5.38 0 5.38z"
          fill="#FFAB00"
        />
        <g transform="translate(4 12)">
          <ellipse fill="#FFF" cx={9.629} cy={11.852} rx={3.653} ry={3.556} />
          <ellipse fill="#FFF" cx={39.603} cy={11.852} rx={3.653} ry={3.556} />
          <g stroke="#000" strokeWidth={1.2}>
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M27.662 8.296h2.398"
            />
            <path
              d="M46.4 13.385l2.588-.995a2.37 2.37 0 001.454-2.18V8.48c0-1-.634-1.891-1.585-2.23l-6.42-2.287-24.88-.111L4.082 6.948A4.919 4.919 0 00.3 11.007l-.288 1.946a1.187 1.187 0 001.186 1.357h4.882m10.521 0h19"
              strokeLinecap="round"
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M50.442 11.259H52"
            />
            <path strokeLinecap="round" d="M17.558 3.852L25.864 0" />
            <path
              d="M42.166 3.852L36.517.73c-2.554 1.695-3.6 6.221-3.137 13.579"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <ellipse
              strokeLinejoin="round"
              cx={39.708}
              cy={12.444}
              rx={3.653}
              ry={3.556}
            />
            <ellipse
              strokeLinejoin="round"
              cx={9.734}
              cy={12.444}
              rx={3.653}
              ry={3.556}
            />
            <path
              d="M18.208 4.431l-.965 2.098a1.2 1.2 0 00-.095.692l1.06 6.564h0"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgLaxuryhover = React.memo(SvgLaxuryhover);
export default MemoSvgLaxuryhover;
