import * as React from 'react';

const SvgThumbtack = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path id="thumbtack_svg__a" d="M0 0h13v13H0z" />
      </defs>
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle fill="#FFDFC4" cx={11} cy={11} r={11} />
        <g transform="translate(5 4)">
          <mask id="thumbtack_svg__b" fill="#fff">
            <use xlinkHref="#thumbtack_svg__a" />
          </mask>
          <path
            d="M7.932 1.076c-.408.588-.347 1.486.185 2.046a46.445 46.445 0 001.752 1.75c.552.523 1.486.586 2.052.185l-3.989-3.98m-.082 2.89L4.495 6.332l2.162 2.162 2.377-3.36L7.85 3.967m-1.56 7.89c.036-.056.072-.107.103-.162A2.231 2.231 0 006.057 9c-.641-.664-1.307-1.305-1.955-1.963a2.315 2.315 0 00-1.909-.718c-.39.03-.753.153-1.068.386l5.165 5.153M13 5.112c-.76.934-2.125 1.334-3.4.517L8.062 7.804c-.296.419-.59.84-.891 1.256-.062.085-.067.148-.02.245.609 1.276.243 2.743-.853 3.63L3.43 10.061.489 13 0 12.505l2.916-2.91-2.895-2.88c.42-.503.913-.849 1.52-1.013a3.168 3.168 0 012.15.154c.05.023.14.024.182-.006 1.164-.814 2.324-1.633 3.496-2.46-.404-.614-.547-1.277-.38-1.993.136-.58.468-1.033.894-1.397L13 5.112"
            fill="#FF7100"
            mask="url(#thumbtack_svg__b)"
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgThumbtack = React.memo(SvgThumbtack);
export default MemoSvgThumbtack;
