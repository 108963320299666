import * as React from 'react';

const SvgPrint = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.617.1l.115.007.107.023a.747.747 0 01.525.623l.006.099v1.806l.097.1 2.239.067.165.02c1.038.161 1.865 1.05 2.007 2.185l.016.168.006.183v5.431l-.007.194-.018.178c-.15 1.118-.975 2.01-2.027 2.162l-.156.018-.175.006h-1.08l-.1.098-.061 3.685-.008.11-.023.106a.747.747 0 01-.622.525l-.099.006H4.47l-.108-.007-.107-.023a.747.747 0 01-.525-.623l-.006-.099V13.53l-.095-.1-1.333-.067-.166-.02c-1.039-.161-1.866-1.05-2.008-2.185l-.016-.168-.006-.183V5.376l.007-.194.018-.178c.15-1.118.975-2.01 2.027-2.163l.156-.017.175-.006H4.47l.1-.097L4.63.85l.008-.111L4.66.63a.747.747 0 01.622-.525L5.382.1h7.235zm.007 10.552H5.376l-.1.099-.06 5.496.099.101 7.308.06.101-.098.06-5.497-.09-.132-.07-.03zm2.9-6.343H2.476l-.123.011-.115.03c-.298.103-.53.38-.614.734l-.023.128-.01.154v5.446l.008.148.024.136c.082.354.309.635.606.74l.108.029.124.013h1.102l.1-.096.06-1.873.008-.111.023-.106a.747.747 0 01.622-.526l.099-.006h9.054l.108.008.107.023a.747.747 0 01.525.622l.006.1v1.805l.095.1 1.147.06.128-.01.115-.03c.298-.103.53-.381.614-.734l.023-.129.01-.153V5.376l-.007-.137-.019-.12c-.097-.467-.447-.81-.859-.81zm-3.806-2.718H6.282l-.1.094-.06.966.098.107 5.497.06.1-.094.061-.966-.09-.137-.07-.03z"
        fill="#000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgPrint = React.memo(SvgPrint);
export default MemoSvgPrint;
