import * as React from 'react';

const SvgAirCon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M5.067 6.267a.133.133 0 00-.133.133v7.333c0 .736.597 1.333 1.333 1.333h19.467c.736 0 1.333-.597 1.333-1.333V6.4a.133.133 0 00-.133-.133H5.067zm0-2.267h21.867a2.4 2.4 0 012.4 2.4v7.333a3.6 3.6 0 01-3.6 3.6H6.267a3.6 3.6 0 01-3.6-3.6V6.4a2.4 2.4 0 012.4-2.4z" />
      <path d="M8.533 10H24a1.333 1.333 0 010 2.666H8.533a1.333 1.333 0 010-2.666zM7.663 24.002l.005-.022-.001.008-.004.014zm.039.27c.095.439.357 1.096.786 1.943a1 1 0 11-1.784.904c-1.181-2.33-1.485-3.74-.375-4.508-.106.073-.082-.001-.082-.277 0-.481-.106-1.189-.322-2.103a1 1 0 011.946-.46c.525 2.222.565 3.594-.201 4.32.005.048.015.109.031.181zM16.329 25.335l.005-.022-.001.008-.004.014zm.04.27c.095.439.357 1.096.786 1.943a1 1 0 11-1.784.904c-1.181-2.33-1.485-3.74-.374-4.508-.106.073-.082-.001-.082-.277 0-.481-.106-1.189-.322-2.103a1 1 0 011.946-.46c.525 2.222.565 3.594-.201 4.32.005.048.015.109.031.181zM24.996 24.002l.005-.022-.001.008-.004.014zm.04.27c.095.439.357 1.096.786 1.943a1 1 0 11-1.784.904c-1.181-2.33-1.485-3.74-.375-4.508-.106.073-.082-.001-.082-.277 0-.481-.106-1.189-.322-2.103a1 1 0 011.946-.46c.525 2.222.565 3.594-.201 4.32.005.048.015.109.031.181z" />
    </svg>
  );
};

const MemoSvgAirCon = React.memo(SvgAirCon);
export default MemoSvgAirCon;
