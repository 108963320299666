import * as React from 'react';

const SvgVehicle = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.8 10.6c-.3-.3-.6-.6-1-.7l-.6-.2-.8-1.9c-.2-.5-.6-1-1.1-1.3-.5-.3-1.1-.5-1.7-.5h-4.9c-.7 0-1.4.3-2 .7L7.1 9.2c-.3.2-.6.4-1 .4l-2.4.4c-.5.1-.9.3-1.2.7-.2.4-.4.8-.4 1.3L2 14c0 .5.2 1 .5 1.4.4.4.8.6 1.4.6h1.4c.3 1.3 1.4 2.2 2.7 2.2 1.3 0 2.4-.9 2.7-2.2h3.4c.3 1.3 1.4 2.2 2.7 2.2 1.3 0 2.4-.9 2.7-2.2h.7c.5 0 .9-.2 1.3-.6.4-.4.6-.9.6-1.4v-2.2c0-.5 0-.8-.3-1.2zm-6-3.1h.9c.3 0 .6.1.8.3.2.2.4.4.6.7l.6 1.4h-2.9V7.5zm-5 .4c.3-.2.6-.4 1-.4h2.5v2.3H8.6l2.2-1.9zM8 16.7c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zm8.8 0c-.7 0-1.3-.6-1.3-1.3 0-.7.6-1.3 1.3-1.3.7 0 1.3.6 1.3 1.3 0 .7-.6 1.3-1.3 1.3zm3.9-2.7c0 .1-.1.3-.1.4 0 .1-.2.1-.4.1h-.8c-.4-1-1.4-1.8-2.6-1.8-1.2 0-2.2.7-2.6 1.8h-3.7c-.4-1-1.4-1.8-2.6-1.8-1.2 0-2.2.7-2.6 1.8H4c-.1 0-.3-.1-.3-.2-.1-.1-.1-.2-.1-.4l.1-2c0-.1.1-.2.1-.3 0 0 .1 0 .2-.1l1.5-.2h14.8c.1 0 .2.1.3.2.1.1.1.2.1.3V14z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgVehicle = React.memo(SvgVehicle);
export default MemoSvgVehicle;
