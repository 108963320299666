import * as React from 'react';

const SvgSeatsNumber = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 26 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M23.923 3.626l-.839 1.947c-.076.184-.267.257-.458.22l-2.48-.514a.442.442 0 01-.266-.184c-.077-.073-.077-.183-.039-.294l.42-1.8a1.929 1.929 0 011.87-1.506h.19c.534.074.992.331 1.297.735.305.404.381.919.305 1.396zM17.247 28.164c0 .22-.229.33-.458.33H4.315c-.23 0-.42-.147-.42-.33V26.51h10.873c.877 0 1.755-.258 2.48-.698v2.352zM17.972 22.653c-.382 1.396-1.717 2.387-3.205 2.387H3.132c-.534 0-1.145-.477-1.145-.992v-.146c0-1.176.458-2.094 1.183-2.755.801-.735 2.098-1.139 3.548-1.139h.496l5.455.551c1.03.11 1.984-.55 2.136-1.506l1.336-6.391c.381-1.69 1.068-2.902 2.06-3.49.763-.44 1.716-.55 2.823-.257l.725.184c.19.073.343.257.305.44l-4.082 13.114z"
          fill="#FFF"
        />
        <path
          d="M21.902 10.027l-3.93 12.626c-.382 1.396-1.717 2.387-3.205 2.387H4.49l.004-.136c.06-1.058.503-1.893 1.176-2.507.801-.735 2.098-1.139 3.548-1.139h.496l5.455.551c1.03.11 1.984-.55 2.136-1.506l1.336-6.391c.381-1.69 1.068-2.902 2.06-3.49.359-.207.76-.342 1.2-.395z"
          fill="#8DCED6"
        />
        <path
          d="M24.877 1.386C24.343.65 23.466.136 22.512.026c-1.717-.22-3.357.955-3.739 2.645l-.42 1.8c-.114.478-.037.992.268 1.396.305.404.725.698 1.22.808l2.48.514c.153.037.267.037.42.037.763 0 1.488-.44 1.793-1.139l.877-2.02.038-.11a3.5 3.5 0 00-.572-2.572zm-.954 2.24l-.839 1.947c-.076.184-.267.257-.458.22l-2.48-.514a.442.442 0 01-.266-.184c-.077-.073-.077-.183-.039-.294l.42-1.8a1.929 1.929 0 011.87-1.506h.19c.534.074.992.331 1.297.735.305.404.381.919.305 1.396zM21.405 7.483c-1.525-.404-2.899-.258-4.005.404-1.336.808-2.29 2.35-2.747 4.444l-1.335 6.465c-.038.22-.229.33-.458.33l-5.493-.514c-2.137-.11-4.044.404-5.227 1.506C1.11 21.037.5 22.36.5 23.938v.147c0 1.065.877 1.983 1.907 2.314V28.2c0 1.029.878 1.8 1.946 1.8h12.475c1.068 0 1.984-.771 1.984-1.8v-3.674c.305-.404.534-.882.686-1.396l4.044-13.076c.228-1.102-.382-2.094-1.412-2.388l-.725-.183zm-4.158 20.68c0 .221-.229.331-.458.331H4.315c-.23 0-.42-.147-.42-.33V26.51h10.873c.877 0 1.755-.258 2.48-.698v2.352zm.725-5.51c-.382 1.396-1.717 2.387-3.205 2.387H3.132c-.534 0-1.145-.477-1.145-.992v-.146c0-1.176.458-2.094 1.183-2.755.801-.735 2.098-1.139 3.548-1.139h.496l5.455.551c1.03.11 1.984-.55 2.136-1.506l1.336-6.391c.381-1.69 1.068-2.902 2.06-3.49.763-.44 1.716-.55 2.823-.257l.725.184c.19.073.343.257.305.44l-4.082 13.114z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgSeatsNumber = React.memo(SvgSeatsNumber);
export default MemoSvgSeatsNumber;
