import * as React from 'react';

const SvgMobileMail = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      {...props}
    >
      <path d="M28.813 7.354h-7.327V2.765C21.486 1.24 20.235 0 18.697 0H4.955C3.417 0 2.166 1.241 2.166 2.765v26.47C2.166 30.76 3.417 32 4.955 32h13.742c1.538 0 2.789-1.24 2.789-2.765v-8.256h7.327c.522 0 .945-.42.945-.938V8.291a.941.941 0 00-.945-.938zm-8.257 5.661l-5.349-3.786h10.698l-5.349 3.786zM4.955 1.875h13.742c.495 0 .898.399.898.89v1.362a3.463 3.463 0 00-1.576-.377H5.629c-.566 0-1.1.136-1.572.376V2.765c0-.491.403-.89.898-.89zm13.742 28.25H4.955a.895.895 0 01-.898-.89v-5.111a3.471 3.471 0 001.572.376h12.39c.567 0 1.103-.137 1.576-.377v5.112c0 .491-.403.89-.898.89zm.898-9.063c0 .862-.707 1.563-1.576 1.563H5.629a1.57 1.57 0 01-1.572-1.512V7.137a1.57 1.57 0 011.572-1.512h12.39c.869 0 1.576.701 1.576 1.563v.167h-7.296a.941.941 0 00-.945.938v11.75c0 .518.423.938.945.938h7.296v.083zm-6.351-1.958v-8.96l6.762 4.786a.975.975 0 001.099 0l6.762-4.786v8.96H13.244z" />
      <path d="M14.348 26.375H9.305c-.522 0-.945.42-.945.938s.423.938.945.938h5.043c.522 0 .945-.42.945-.938s-.423-.938-.945-.938z" />
    </svg>
  );
};

const MemoSvgMobileMail = React.memo(SvgMobileMail);
export default MemoSvgMobileMail;
