import * as React from 'react';

const SvgSmallhover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M16 24h28v-5.474l-17.156-.387L23.049 17l-5.832 1.526L16 24z"
          fill="#FFAB00"
        />
        <g transform="translate(16 12)">
          <path
            d="M4.165 12.244H1.217A1.224 1.224 0 010 11.014v-1.66c0-.844.043-2.433.744-2.891.468-.306 2.873-.795 7.217-1.469C10.45 2.012 11.196 0 15.056 0h6.754c3.71 0 3.953 1.234 4.973 3.35l.72 2.667c.327.679.497 1.424.497 2.179v2.818c0 .68-.545 1.23-1.217 1.23H25.37"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
          />
          <path
            d="M18.764 12.244H13.59"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse fill="#FFF" cx={6.743} cy={12.923} rx={3.091} ry={3.077} />
          <ellipse fill="#FFF" cx={21.352} cy={12.923} rx={3.091} ry={3.077} />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={7.049}
            cy={12.939}
            rx={3.075}
            ry={3.061}
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={21.81}
            cy={12.939}
            rx={3.075}
            ry={3.061}
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="square"
            d="M8.219 5.232l2.833.89h15.712M19.69 5.232V.8"
          />
        </g>
        <path
          stroke="#000"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M31.232 20.905h1.218"
        />
      </g>
    </svg>
  );
};

const MemoSvgSmallhover = React.memo(SvgSmallhover);
export default MemoSvgSmallhover;
