import * as React from 'react';

const SvgImage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 12"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.333.667c.737 0 1.334.597 1.334 1.333v8c0 .736-.597 1.333-1.334 1.333H1.667A1.333 1.333 0 01.333 10V2C.333 1.264.93.667 1.667.667h10.666zm0 1.333H1.667v8h10.666V2zM6.321 4.99a.5.5 0 01.704.072l1.102 1.352a.5.5 0 00.52.166l.902-.247a.5.5 0 01.535.185l.995 1.35a.5.5 0 01-.403.797l-7.309-.01a.5.5 0 01-.323-.88l3.27-2.778zM4 3.334a1 1 0 110 2 1 1 0 010-2z"
        fill="#000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgImage = React.memo(SvgImage);
export default MemoSvgImage;
