import * as React from 'react';

const SvgFavorite = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M6.4 12.746c0 3.86 3.673 8.526 9.607 12.52 5.934-3.994 9.593-8.561 9.593-12.52 0-2.729-2.149-4.942-4.8-4.942a4.692 4.692 0 00-3.199 1.258l-1.155 1.064a.666.666 0 01-.892 0l-1.155-1.064A4.697 4.697 0 0011.2 7.804c-2.651 0-4.8 2.213-4.8 4.942zM16 7.221a7.058 7.058 0 014.8-1.888c3.976 0 7.2 3.319 7.2 7.413 0 5.975-6.007 11.738-11.49 15.118-.279.179-.736.181-1.016.002C9.974 24.496 4 18.721 4 12.745c0-4.094 3.224-7.413 7.2-7.413 1.844 0 3.526.714 4.8 1.888z" />
    </svg>
  );
};

const MemoSvgFavorite = React.memo(SvgFavorite);
export default MemoSvgFavorite;
