import * as React from 'react';

const SvgEyeOpened = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={'35px'}
      width={'35px'}
      viewBox="0 0 32 32"
      {...props}
    >
      <title>opened eye icon</title>
      <g
        id="icon_eye"
        stroke="none"
        stroke-width="1"
        fill="none"
        fill-rule="evenodd"
      >
        <g
          id="witness"
          transform="translate(1.000000, 1.000000)"
          fill-rule="nonzero"
        >
          <path
            d="M29.3168,15.184 L22.3088,21.024 L15.3008,22.192 C12.1470015,22.0206139 9.81100152,21.6312806 8.2928,21.024 C6.77459848,20.4167194 5.21726515,19.6380527 3.6208,18.688 L1.2848,15.184 L4.7888,11.68 L10.6288,9.344 L17.6368,8.176 L23.4768,10.512 L29.3168,15.184 Z"
            id="Path-2"
            fill="#FFFFFF"
          ></path>
          <path
            d="M30.4484584,14.7546674 C27.6624621,11.3603595 21.764045,7.39921154 15.326496,7.39921154 C8.86586705,7.39921154 2.96720963,11.387286 0.204533592,14.7546674 C-0.0682179332,15.0871021 -0.0682179332,15.5658899 0.204533592,15.8983246 C3.05658413,19.3747345 8.96882504,23.2538406 15.326496,23.2538406 C21.9100975,23.2538406 27.7833911,19.1453179 30.4484584,15.8983847 C30.7212099,15.5658899 30.7212099,15.0871021 30.4484584,14.7546674 Z M21.4506633,15.326496 C21.4506633,18.7033738 18.7033738,21.4507234 15.3264359,21.4507234 C11.9494378,21.4507234 9.20220851,18.7031935 9.20220851,15.326496 C9.20220851,11.9496182 11.9494979,9.20226861 15.3264359,9.20226861 C18.7036744,9.20226861 21.4506633,11.9499788 21.4506633,15.326496 Z M2.11938393,15.3267364 C3.13411817,14.2720932 5.58082797,11.9960184 9.01546567,10.5351929 C6.8600194,13.3674092 6.85917795,17.2832388 9.01444391,20.1164768 C6.4029893,19.0096634 3.97707545,17.2610003 2.11938393,15.3267364 Z M21.6375263,20.1177991 C23.7929726,17.2855227 23.7937539,13.3697532 21.6385481,10.5365152 C24.2498825,11.6433286 26.6758564,13.3920518 28.5336081,15.3261955 C27.5188738,16.3809589 25.072164,18.6569135 21.6375263,20.1177991 Z"
            id="Shape"
            fill="#FF7100"
          ></path>
          <path
            d="M12.2611968,15.326496 C12.2611968,17.016738 13.636254,18.3917952 15.326496,18.3917952 C17.0166779,18.3917952 18.3917952,17.016738 18.3917952,15.326496 C18.3917952,13.636254 17.0166779,12.2611968 15.326496,12.2611968 C13.636254,12.2611968 12.2611968,13.636254 12.2611968,15.326496 Z M16.588678,15.326496 C16.588678,16.0224992 16.0224391,16.588678 15.326496,16.588678 C14.6304928,16.588678 14.064314,16.0224992 14.064314,15.326496 C14.064314,14.6304928 14.6304928,14.064314 15.326496,14.064314 C16.0224391,14.064314 16.588678,14.6304928 16.588678,15.326496 Z"
            id="Shape"
            fill="#FF7100"
          ></path>
          <path
            d="M15.326496,3.90675388 C15.8243968,3.90675388 16.2280546,3.50309605 16.2280546,3.00519529 L16.2280546,0.901558588 C16.2280546,0.403657832 15.8243968,0 15.326496,0 C14.8285952,0 14.4249374,0.403657832 14.4249374,0.901558588 L14.4249374,3.00519529 C14.4249374,3.50309605 14.8285952,3.90675388 15.326496,3.90675388 Z"
            id="Path"
            fill="#FF7100"
          ></path>
          <path
            d="M20.7057956,5.11255844 C21.1380629,5.35910466 21.688795,5.2087848 21.9355816,4.77603667 L23.1353758,2.67239997 C23.3820422,2.23989226 23.2314819,1.68934048 22.7989141,1.44261395 C22.3662862,1.19594752 21.8158546,1.3465078 21.5691281,1.77913572 L20.3693339,3.88277242 C20.1226675,4.31522003 20.2732879,4.86583191 20.7057956,5.11255844 Z"
            id="Path"
            fill="#FF7100"
          ></path>
          <path
            d="M8.71741041,4.77603667 C8.96407684,5.20854438 9.51462861,5.35916477 9.94719642,5.11249834 C10.3797041,4.86583191 10.5303245,4.31522003 10.2836581,3.88271232 L9.08386392,1.77907561 C8.83719749,1.34656791 8.2865255,1.19594752 7.8540779,1.44261395 C7.42157019,1.68928038 7.27094981,2.23989226 7.51761624,2.67239997 L8.71741041,4.77603667 Z"
            id="Path"
            fill="#FF7100"
          ></path>
          <path
            d="M15.326496,26.7462381 C14.8285952,26.7462381 14.4249374,27.1498959 14.4249374,27.6477967 L14.4249374,29.7514334 C14.4249374,30.2493342 14.8285952,30.652992 15.326496,30.652992 C15.8243968,30.652992 16.2280546,30.2493342 16.2280546,29.7514334 L16.2280546,27.6477967 C16.2280546,27.1498959 15.8243968,26.7462381 15.326496,26.7462381 Z"
            id="Path"
            fill="#FF7100"
          ></path>
          <path
            d="M9.94719642,25.5404937 C9.51468872,25.2938873 8.96413694,25.4444476 8.71741041,25.8769553 L7.51761624,27.980592 C7.27094981,28.4130997 7.42157019,28.9637116 7.8540779,29.2103781 C8.28664571,29.4570445 8.83719749,29.306364 9.08386392,28.8739164 L10.2836581,26.7702797 C10.5303846,26.337772 10.3797041,25.7871601 9.94719642,25.5404937 L9.94719642,25.5404937 Z"
            id="Path"
            fill="#FF7100"
          ></path>
          <path
            d="M21.9355816,25.8769553 C21.6889152,25.4443875 21.1381831,25.2938873 20.7057956,25.5404336 C20.2732879,25.7871 20.1226675,26.3377119 20.3693339,26.7702196 L21.5691281,28.8738563 C21.8157344,29.306364 22.3662862,29.4570445 22.7989141,29.2103781 C23.2314218,28.9637116 23.3820422,28.4130997 23.1353758,27.980592 L21.9355816,25.8769553 Z"
            id="Path"
            fill="#FF7100"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgEyeOpened = React.memo(SvgEyeOpened);
export default MemoSvgEyeOpened;
