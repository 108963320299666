import * as React from 'react';

const SvgExclamationMarkWarning = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(1 1)" fill="none" fillRule="evenodd">
        <circle fill="#FFE8E8" cx={11} cy={11} r={11} />
        <path
          d="M11 4.49a6.667 6.667 0 110 13.334 6.667 6.667 0 010-13.333zm0 1.334a5.333 5.333 0 100 10.667 5.333 5.333 0 000-10.667zm0 7.334a.667.667 0 110 1.333.667.667 0 010-1.333zm0-6c.368 0 .667.298.667.666v4.105a.667.667 0 01-1.334 0V7.824c0-.368.299-.666.667-.666z"
          fill="#FF2A2B"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

const MemoSvgExclamationMarkWarning = React.memo(SvgExclamationMarkWarning);
export default MemoSvgExclamationMarkWarning;
