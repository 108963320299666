import * as React from 'react';

const SvgCarPrice = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.501 2.02a3.73 3.73 0 013.27 3.41l.005.167c.008.333.017.666.025 1.043a.747.747 0 010 .26v.011c-.01.431-.026 1.129-.77 1.129-.76 0-.76-.62-.76-1.05V5.56a2.26 2.26 0 00-1.87-2l-1.34-.06a1.26 1.26 0 00-1.22 1.25v7a.75.75 0 01-1.5 0v-7A2.76 2.76 0 017.061 2l1.44.02zm13.3 12.54a2 2 0 00-1-.72l-.56-.17-.77-1.86a3 3 0 00-1.14-1.25 3 3 0 00-1.66-.5h-4.89a3 3 0 00-2 .73l-2.72 2.36a1.91 1.91 0 01-1 .46l-2.37.41a2 2 0 00-1.63 1.91l-.06 2A2 2 0 003.941 20h.42a2.73 2.73 0 005.4 0h4.6a2.73 2.73 0 005.4 0h.44a2 2 0 002-2v-2.2a2 2 0 00-.4-1.24zm-6.04-3h.91a1.49 1.49 0 011.38.92l.57 1.36h-2.86v-2.28zm-4 0a1.48 1.48 0 00-1 .37l-2.16 1.91h5.66v-2.28h-2.5zm-3.77 8.81c-.105.11-.226.205-.36.28l-.13.07a1.12 1.12 0 01-.88 0l-.13-.07a.861.861 0 01-.22-.15.86.86 0 01-.14-.13 1.275 1.275 0 01-.13-.19 1.19 1.19 0 01.68-1.79.94.94 0 01.76 0 1.19 1.19 0 01.68 1.79c-.037.067-.08.13-.13.19zm9.87.13c.046-.04.09-.084.13-.13.05-.06.093-.123.13-.19a1.19 1.19 0 00-.68-1.79.94.94 0 00-.76 0 1.19 1.19 0 00-.68 1.79c.038.067.081.13.13.19.106.11.227.205.36.28l.13.07c.281.12.6.12.88 0l.13-.07c.083-.04.16-.09.23-.15zm2.84-2.49a.51.51 0 01-.15.35.47.47 0 01-.35.15h-.6a2.75 2.75 0 00-5.08 0h-4.92a2.75 2.75 0 00-5.08 0h-.55a.5.5 0 01-.47-.52l.06-2a.53.53 0 01.12-.31.57.57 0 01.3-.17l1.51-.27v.06h14.85a.5.5 0 01.36.48v2.23zM14.061 2a.76.76 0 00-.75.75v4a.75.75 0 001.5 0v-4a.76.76 0 00-.75-.75zM7.534 5.177a.75.75 0 01.527-.227.75.75 0 01.74.74c.005.424.018.867.032 1.33.018.632.038 1.301.038 2.01V9.45a.76.76 0 01-.65.81h-.1a.72.72 0 01-.73-.61 2.14 2.14 0 010-.45v-.13c-.07-1.24-.07-2.36-.07-3.36a.75.75 0 01.213-.533z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgCarPrice = React.memo(SvgCarPrice);
export default MemoSvgCarPrice;
