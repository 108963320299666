import * as React from 'react';

const SvgDiagram = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M412 205l44 62-219 156 18 26 220-157 59 84 98-216-236 23 16 22zm168-8l-52 115-74-103zM192 864h181V594H192v270zm32-238h117v206H224V626zm197 238h182V493H421v371zm32-339h118v307H453V525zm198 339h181V291H651v573zm32-541h117v509H683V323z" />
    </svg>
  );
};

const MemoSvgDiagram = React.memo(SvgDiagram);
export default MemoSvgDiagram;
