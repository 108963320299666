import * as React from 'react';

const SvgCompareVehicles = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M13.657 20a436.88 436.88 0 00-3.806-2.946 1.332 1.332 0 111.623-2.115c7.709 5.914 7.188 5.498 7.188 6.391s.52.477-7.188 6.391a1.333 1.333 0 01-1.623-2.115 441.731 441.731 0 003.796-2.938H3.999a1.333 1.333 0 010-2.666h9.657zm4.695-8c1 .784 2.266 1.764 3.796 2.938a1.332 1.332 0 11-1.623 2.115c-7.709-5.914-7.188-5.498-7.188-6.391s-.521-.477 7.188-6.391a1.333 1.333 0 011.623 2.115 452.74 452.74 0 00-3.806 2.946h9.657a1.333 1.333 0 010 2.666h-9.648z" />
    </svg>
  );
};

const MemoSvgCompareVehicles = React.memo(SvgCompareVehicles);
export default MemoSvgCompareVehicles;
