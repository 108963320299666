import * as React from 'react';

const SvgVacation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M19.544 20.469h-5.93l-.89-8.499 2.565-.264 5.019-.517a.768.768 0 00.688-.84c-.232-2.21-1.39-4.185-3.264-5.563-1.854-1.363-4.19-1.978-6.573-1.733-4.95.51-8.592 4.612-8.117 9.145a.77.77 0 00.847.683l5.004-.516h.01l2.286-.236.873 8.34H5.706a.77.77 0 00-.772.765.77.77 0 00.772.766h13.838a.77.77 0 00.772-.766.77.77 0 00-.772-.765zM9.527 10.76c-.217-3.664.863-6.089 1.79-6.184.92-.101 2.486 2.056 3.033 5.688l-4.823.496zm9.801-1.01l-3.442.355c-.326-2.233-1.016-4.109-1.906-5.367a7.6 7.6 0 012.831 1.278c1.303.958 2.175 2.265 2.517 3.734zM8.743 5.282c-.608 1.412-.895 3.388-.75 5.636l-3.444.355c.055-2.562 1.726-4.856 4.194-5.99z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgVacation = React.memo(SvgVacation);
export default MemoSvgVacation;
