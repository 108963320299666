import * as React from 'react';

const MinusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M14.815 14.667H6.519c-.658 0-1.185.597-1.185 1.333 0 .741.531 1.333 1.185 1.333h18.963c.658 0 1.185-.597 1.185-1.333 0-.742-.531-1.333-1.185-1.333H14.815z" />
    </svg>
  );
};

export default MinusIcon;
