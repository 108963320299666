import * as React from 'react';

const SvgPets = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.548 3.85a2.313 2.313 0 00-1.862.937l-4.664 5.51a.722.722 0 00-.172.466c0 1.597 1.317 2.892 2.933 2.892.531 0 1.03-.14 1.46-.385v1.823c0 1.616 1.22 2.954 2.797 3.17A3.248 3.248 0 0012 20.15a3.247 3.247 0 002.96-1.887c1.577-.217 2.797-1.554 2.797-3.17V13.27c.43.245.929.385 1.46.385 1.616 0 2.933-1.295 2.933-2.892 0-.17-.06-.336-.171-.467l-4.665-5.51a2.265 2.265 0 00-.267-.301 2.314 2.314 0 00-1.595-.635H8.548zM7.85 5.68l.126-.15a.838.838 0 01.573-.225h6.904c.223 0 .424.086.574.227l.123.146a.809.809 0 01.136.448v8.967c0 .96-.794 1.746-1.774 1.746a1.773 1.773 0 01-1.716-1.3c.164-.127.307-.293.424-.491l.628-1.072c.28-.48.318-1.004.068-1.43-.249-.426-.727-.655-1.287-.655h-1.256c-.56 0-1.038.23-1.287.655-.25.426-.212.95.069 1.43l.627 1.072c.117.198.26.364.424.491-.2.747-.893 1.3-1.715 1.3-.98 0-1.775-.785-1.775-1.746v-8.97c0-.163.05-.315.134-.444zm9.908 5.083V7.576l2.9 3.427a1.457 1.457 0 01-1.44 1.197 1.45 1.45 0 01-1.46-1.437zM4.783 12.2c-.724 0-1.324-.52-1.44-1.197l2.9-3.427v3.187a1.45 1.45 0 01-1.46 1.437zM12 14.224l-.514-.878h1.029l-.515.878zm0 4.471a1.786 1.786 0 01-1.35-.612 3.258 3.258 0 001.35-.962c.355.426.82.761 1.35.962a1.786 1.786 0 01-1.35.612zM10 10a1 1 0 100-2 1 1 0 000 2zm5-1a1 1 0 11-2 0 1 1 0 012 0z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgPets = React.memo(SvgPets);
export default MemoSvgPets;
