import * as React from 'react';

const SvgWhatsapp = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M15.25 7.942c0 3.833-3.132 6.94-6.996 6.94a7.017 7.017 0 01-3.381-.862L1 15.25l1.263-3.725A6.87 6.87 0 011.259 7.94C1.26 4.108 4.391 1 8.255 1c3.864 0 6.995 3.108 6.995 6.942zM8.253 2.107c-3.242 0-5.88 2.618-5.88 5.836 0 1.277.416 2.46 1.12 3.421l-.734 2.167 2.259-.718a5.88 5.88 0 003.235.964c3.243 0 5.882-2.617 5.882-5.835.001-3.217-2.638-5.835-5.882-5.835zm3.534 7.434c-.044-.07-.158-.113-.33-.199-.17-.085-1.014-.497-1.17-.552-.159-.057-.273-.086-.387.084-.113.17-.442.554-.543.668-.1.114-.2.128-.371.044-.172-.086-.725-.265-1.38-.844a5.119 5.119 0 01-.953-1.179c-.101-.17-.01-.262.075-.346.076-.077.171-.2.257-.299.086-.099.114-.17.171-.283.057-.114.028-.213-.014-.299-.043-.085-.385-.922-.529-1.262-.142-.34-.285-.284-.385-.284-.1 0-.215-.014-.329-.014-.114 0-.3.041-.457.212-.156.17-.6.582-.6 1.419 0 .838.614 1.646.7 1.76.087.113 1.187 1.887 2.93 2.568 1.744.681 1.744.454 2.058.425.315-.029 1.015-.411 1.157-.808.143-.399.143-.74.1-.811z"
        fill="#0FCA80"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgWhatsapp = React.memo(SvgWhatsapp);
export default MemoSvgWhatsapp;
