/* eslint-disable max-lines */
import axios from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';

export type HomePageResults = {
  data: HomePage;
};

export type HomePage = {
  manufacturers: Manufacturer[];
  models: Model[];
};

export type Manufacturer = {
  id: number;
  title: string;
  img: string;
};

type ModelBase = {
  id: number;
  title: string;
  img: string;
};

export type Model = ModelBase & {
  manufacturer: number;
  minPrice: number;
  year: number;
};

export type WizardModel = ModelBase & {
  minYear: number;
  maxYear: number;
  manufacturerId: number;
};

export type SubModels = {
  id: number;
  title: string;
  minYear: number;
  maxYear: number;
};

export type FeedItemType = {
  modelId: number;
  model: string;
  manufacturer: string;
  img: string;
  isNew: number;
  year: number;
  familyType: string;
  minPrice: number;
  maxPrice: number;
  subModels: FeedSubModel[];
};

export type FeedSubModel = {
  id: number;
  title: string;
  horsepower: number;
  engineType: string;
  price: number;
};

export type FeedResults = {
  models: FeedItemType[];
  pagination: FeedPagination;
  searchParams: SearchParams;
};

export type FeedPagination = {
  page: number;
  limit: number;
  total: number;
};

export type SearchParams = Partial<
  Record<'carFamilyType' | 'manufacturer' | 'model', LiteralItem[]> &
    Record<
      'min-price' | 'max-price' | 'min-year' | 'max-year' | 'seats' | 'page',
      number
    >
>;

export type LiteralItem = {
  title: string;
  engTitle: string;
  id: number;
};

export type SubModelParams = {
  year: string;
  subModelId: string;
};

export type IdText = {
  id: number;
  name: string;
};

export type IdTextListing = {
  id: number;
  text: string;
};

type YearsRange = {
  from: number;
  to: number;
};

export type SubModel = {
  make: IdText;
  model: IdText;
  modelMaster: IdText;
  smId: number;
  year: number;
  yearsRange: YearsRange;
  isNewCar: boolean;
  hasNewCar: boolean;
  price: number;
  images: string[];
  airPollutionLevel: number;
  info: {
    horsePower: number;
    fuelType: IdText;
    bodyType: IdText;
    transmissionType: IdText;
    driveWheelType: IdText;
    powertrainArchitectureType: IdText;
    engine: number;
    doors: number;
    seats: number;
    carFamilyType: string[];
  };
  technicalSpecification: {
    length: number;
    width: number;
    height: number;
    wheelbase: number;
    weight: number;
    minTorque: number;
    maxTorque: number;
    torque: string;
    minPower: number;
    maxPower: number;
    maxSpeed: string;
    minFuelTankCapacity: string;
    maxFuelTankCapacity: string;
    fuelConsumptionCombined: string;
    acceleration: string;
    frontAxleWidth: number;
    rearAxleWidth: number;
    compressionRatio: string;
    minRotationDiameter: string;
  };
};

export type SubModelResult = {
  data: SubModel;
};

export type ConvertSubToMmModelResult = {
  data: {
    makeId: number;
    mmModelId: number;
  };
};

export type Yad2ListingsParams = {
  carFamilyType?: number;
  manufacturer: number;
  model: number;
  year: number;
};

export type Yad2Listing = {
  token: string;
  manufacturer: IdTextListing;
  model: IdTextListing;
  subModel: IdTextListing;
  year: number;
  price: number;
  imageSrc: string;
};

export type Yad2ListingLinkParams = {
  carFamilyType?: string;
  manufacturer?: string;
  model?: string;
  year: string;
};

export type SubModelLegacyReviewsResult = {
  data: SubModelLegacyReviews;
};

export type SubModelLegacyReviews = {
  reviewsCount: number;
  reliabilityAvg: string;
  comfortAvg: string;
  fuelAvg: string;
  worthAvg: string;
  reviews: SubModelLegacyReview[];
};

export type SubModelLegacyReview = {
  delId: number;
  name: string;
  createdAt: string;
  opinionReliability: number;
  opinionComfort: number;
  opinionFuel: number;
  opinionWorth: number;
  advantages: string;
  disadvantages: string;
  info: string;
  opinionAverage: string;
};

export type SubModelReviewsResult = {
  data?: SubModelReviews;
};

export type AllSubModelReviewsResult = {
  data?: AllSubModelReviews;
};

export type AllSubModelReviews = {
  oldReviews: SubModelLegacyReviews;
  newReviews: SubModelReviews;
};
export type SubModelReviews = {
  reviewsCount: number;
  avgReliabilityRating: number;
  avgFuelRating: number;
  avgWorthRating: number;
  avgComfortRating: number;
  reviews: SubModelReview[];
};

export type SubModelReview = {
  _id: string;
  name: string;
  createdAt: string;
  avgRating: number;
  reliabilityRating: number;
  comfortRating: number;
  fuelRating: number;
  worthRating: number;
  prosLabels: string[];
  consLabels: string[];
};

export type ProConRecordReview = { id: string; label: string; isPro: boolean };

export type ProsAndConsLabelsRes = { data: ProConRecordReview[] };

export type Yad2ListingsResult = {
  data: {
    yad2Listings: Yad2Listing[];
    listingLinkParams: Yad2ListingLinkParams;
  };
};

export type CalculationOptionsParams = {
  subModelId: number;
};

export type CalculationOption = {
  text: string;
  value: number;
};

export type WarrantyPercentByType = { [key: number]: CalculationOption[] };

export type CalculationOptionsResults = {
  data: {
    ownershipPercent: CalculationOption[];
    warrantyType: CalculationOption[];
    warrantyPercentByType: WarrantyPercentByType;
  };
};

export type CalculatePriceParams = {
  subModelId?: number;
  modelMasterId: number;
  previousOwnershipPercent?: string;
  kilometers: number;
  ascentYearOnRoad: number;
  ascentMonthOnRoad: number;
  price?: number;
  ownersNumber: number;
  currentOwnershipPercent?: string;
  appraiserReducePercent?: number;
  warrantyPercent?: number;
  hasLPGSystem?: boolean;
  colorId?: number;
};

export type CalculatePriceResult = {
  accuracyId: number;
  maxPrice: number;
  minPrice: number;
  predictedPrice: number;
};

type CalculatePrice = {
  data: CalculatePriceResult;
};

export type LicensePlateResult = {
  data: {
    year: number;
    mm_id: number;
  };
};

export type LastSearchResponse = {
  _id?: string;
  href: string;
  title: string;
  searchDescription: string;
  createdAt: number;
};
export type SmIdAndYearParams = Pick<SubModel, 'smId' | 'year'>;
export type MmIdAndYearParams = { mmId: number } & Pick<SubModel, 'year'>;

export type SaveLastSearch = Omit<LastSearchResponse, '_id'>;

export type CheckAddReview = {
  mmId: number;
  year: number;
  custId: number;
};

export type SaveReview = {
  mmId: string;
  year: string;
  custId: string;
  name: string;
  reliabilityRating: number;
  comfortRating: number;
  fuelRating: number;
  worthRating: number;
  prosLabels: string[];
  prosIds: string[];
  consLabels: string[];
  consIds: string[];
};

const serviceName = 'price-list';

/**
 * client for `price-list` service
 * @see https://gw.yad2.io/price-list/api-docs/#
 */
// eslint-disable-next-line max-lines-per-function
export const priceListClient = (baseUrl = env.routes.api.gw) => {
  const url = getWithSSRSupport(baseUrl, serviceName);
  const client = axios.create({
    baseURL: url,
    withCredentials: true,
  });

  return {
    getHomePage: () => client.get<HomePageResults>('/home-page'),
    getManufacturersSearchOptions: () =>
      client.get<{ data: Manufacturer[] }>('/search-options/manufacturers'),
    getModelsSearchOptions: (manufacturerIds: string) =>
      client.get<{ data: WizardModel[] }>(
        `/search-options/${manufacturerIds}/models`,
      ),
    getSubModelsSearchOptions: (modelId: string) =>
      client.get<{ data: SubModels[] }>(
        `/search-options/${modelId}/sub-models`,
      ),
    getModelsFeed: (queryString: string) =>
      client.get<{ data: FeedResults }>(`feed${queryString}`),
    handleFeedPageRedirect: (queryString: string) =>
      client.get(`feed/convert/model-to-mm-model${queryString}`),
    getSubModel: (params: SubModelParams) =>
      client.get<SubModelResult>('/sub-model', {
        params,
      }),
    handelFeedRedirect: (subModelId: string) =>
      client.get<ConvertSubToMmModelResult>(
        `/sub-model/models?subModelId=${subModelId}`,
      ),
    getSubModelLegacyReviews: ({ smId, year }: SmIdAndYearParams) =>
      client.get<SubModelLegacyReviewsResult>(
        `/sub-model/legacy-reviews/${smId}/${year}`,
      ),
    getSubModelReviews: ({ mmId, year }: MmIdAndYearParams) =>
      client.get<SubModelReviewsResult>(`/sub-model/reviews/${mmId}/${year}`),
    getAllSubModelReviews: ({ mmId, year }: MmIdAndYearParams) =>
      client.get<AllSubModelReviewsResult>(
        `/sub-model/reviews/${mmId}/${year}/all`,
      ),
    getProsAndConsLabel: () =>
      client.get<ProsAndConsLabelsRes>(
        `/sub-model/reviews/pros-and-cons-labels`,
      ),
    checkValidateAddReview: (params: CheckAddReview) =>
      client.get(`/sub-model/reviews/check`, { params }),
    saveReview: (review: SaveReview) =>
      client.post(`/sub-model/reviews`, review, {
        withCredentials: true,
      }),
    getYad2Listings: (params: Yad2ListingsParams) =>
      client.get<Yad2ListingsResult>('/sub-model/yad2-listings', {
        params,
      }),
    getCalculationPriceOptions: (params: CalculationOptionsParams) =>
      client.get<CalculationOptionsResults>(
        '/sub-model/price-calculation-options',
        {
          params,
        },
      ),
    calculatePrice: (params: CalculatePriceParams) =>
      client.get<CalculatePrice>('/calculate-price', { params }),
    getSubModelByLicensePlate: (plateNumber: string) =>
      client.get<LicensePlateResult>(`/license-plate/${plateNumber}`),
    getLastSearches: () =>
      client.get<{ data: LastSearchResponse[] }>(`/last-searches`, {
        withCredentials: true,
      }),
    saveLastSearches: (lastSearches: SaveLastSearch[]) =>
      client.post(`/last-searches`, lastSearches, {
        withCredentials: true,
      }),
    deleteLastSearch: (searchId: LastSearchResponse['_id']) =>
      client.delete(`/last-searches/${searchId}`, {
        withCredentials: true,
      }),
  };
};
