import * as React from 'react';

const SvgPrinter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M23.667 19h1.667a.333.333 0 00.333-.333v-8a.333.333 0 00-.333-.333H6.667a.333.333 0 00-.333.333v8c0 .184.149.333.333.333h3v-.333A2.333 2.333 0 0112 16.334h9.333a2.333 2.333 0 012.333 2.333V19zm-2-.333a.333.333 0 00-.333-.333h-9.333a.333.333 0 00-.333.333V21h-5a2.333 2.333 0 01-2.333-2.333v-8a2.333 2.333 0 012.333-2.333h18.667a2.333 2.333 0 012.333 2.333v8A2.333 2.333 0 0125.335 21h-3.667v-2.333z" />
      <path d="M20.333 8.333V6.666A.333.333 0 0020 6.333h-8a.333.333 0 00-.333.333v1.667h8.667zm-8.333-4h8a2.333 2.333 0 012.333 2.333v3.667H9.666V6.666a2.333 2.333 0 012.333-2.333zM20.435 22.667H12.59a.59.59 0 100 1.18h7.85a.59.59 0 10-.005-1.18zM20.435 20H12.59a.59.59 0 100 1.18h7.85a.59.59 0 10-.005-1.18z" />
      <path d="M12 18.333a.333.333 0 00-.333.333v6.667c0 .184.149.333.333.333h9.333a.333.333 0 00.333-.333v-6.667a.333.333 0 00-.333-.333H12zm0-2h9.333a2.333 2.333 0 012.333 2.333v6.667a2.333 2.333 0 01-2.333 2.333H12a2.333 2.333 0 01-2.333-2.333v-6.667A2.333 2.333 0 0112 16.333zM8.173 12.162a.59.59 0 000 .836.587.587 0 001.004-.418.597.597 0 00-.173-.418.613.613 0 00-.83 0z" />
    </svg>
  );
};

const MemoSvgPrinter = React.memo(SvgPrinter);
export default MemoSvgPrinter;
