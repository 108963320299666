import * as React from 'react';

const SvgComponent = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      viewBox="0 0 24 24"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m12.916 3.643.088-.007.053-.108a2.91 2.91 0 0 1 3.47-1.384 2.914 2.914 0 0 1 1.996 3.164l-.02.116.08.07c2.043 1.833 2.901 4.676 2.145 7.336l-.078.257-.972 2.99.127.093a2.742 2.742 0 0 1 .965 2.835l-.046.16c-.468 1.439-2.022 2.214-3.465 1.745l-1.304-.423c-.526 1.184-1.888 1.799-3.166 1.383-1.278-.415-2.018-1.713-1.748-2.98l-6.127-1.991c-1.446-.47-2.25-2.014-1.78-3.455a2.735 2.735 0 0 1 2.658-1.882l.973-2.992c.89-2.74 3.327-4.645 6.151-4.927ZM5.14 13.769a.757.757 0 0 0-.174.712.818.818 0 0 0 .543.578l12.345 4.012a.816.816 0 0 0 1.027-.51.816.816 0 0 0-.53-1.01 1.138 1.138 0 0 1-.752-.787c-.05-.2-.047-.418.022-.63l1.186-3.651c.313-.963.33-1.968.087-2.905a5.347 5.347 0 0 0-2.333-3.197l-.029-.018-.28-.2.314-.967a.975.975 0 0 0-.634-1.213.976.976 0 0 0-1.228.614l-.313.962-.318-.002-.06-.004a5.333 5.333 0 0 0-4.378 1.812 5.175 5.175 0 0 0-1.027 1.81l-1.186 3.65c-.05.154-.13.29-.231.404-.29.315-.752.451-1.19.309a.814.814 0 0 0-.861.23Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default SvgComponent;
