import * as React from 'react';

const SvgNotePen = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 33 32"
      {...props}
    >
      <path d="M31.402 10.071a2.816 2.816 0 00-3.978 0l-2.82 2.823V5.465c0-.751-.293-1.457-.824-1.989L21.129.824A2.796 2.796 0 0019.14 0H3.042A2.816 2.816 0 00.229 2.813v26.375a2.816 2.816 0 002.813 2.813h18.75a2.816 2.816 0 002.813-2.813v-8.336l6.798-6.804a2.81 2.81 0 000-3.977zM18.979 1.875c.177 0 .518-.031.824.275l2.652 2.652c.298.298.275.624.275.824h-3.75v-3.75zm3.749 27.313c0 .517-.42.938-.938.938H3.04a.94.94 0 01-.938-.938V2.813a.94.94 0 01.938-.938h14.063v4.688c0 .518.42.938.938.938h4.688v7.27l-2.766 2.768-1.325 1.325a.949.949 0 00-.227.366l-1.326 3.977a.939.939 0 001.186 1.186l3.977-1.326a.93.93 0 00.366-.227l.114-.114v6.46zm-2.102-9.661l1.326 1.326-.506.506-1.989.663.663-1.989.506-.506zm2.651-.001L21.951 18.2l4.507-4.51 1.326 1.326-4.507 4.51zm6.799-6.804l-.966.967-1.326-1.326.967-.968a.939.939 0 011.326 1.326z" />
      <path d="M18.041 9.375H4.916a.938.938 0 000 1.876h13.125a.938.938 0 000-1.876zm-3.75 3.75H4.916a.938.938 0 000 1.876h9.375a.938.938 0 000-1.876zm0 3.75H4.916a.938.938 0 000 1.876h9.375a.938.938 0 000-1.876zm0 3.75H4.916a.938.938 0 000 1.876h9.375a.938.938 0 000-1.876zm3.75 5.75h-5.625a.938.938 0 000 1.876h5.625a.938.938 0 000-1.876z" />
    </svg>
  );
};

const MemoSvgNotePen = React.memo(SvgNotePen);
export default MemoSvgNotePen;
