import * as React from 'react';

const SvgLink = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 13"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="#000000" fillRule="nonzero">
        <path d="M8.861.903H6.095l-.218.004C2.911 1.02.556 3.505.556 6.535c0 3.036 2.36 5.511 5.317 5.627l.217.005h2.736l.103-.005a.82.82 0 00.013-1.628l-.103-.006H6.083l-.2-.006c-2.063-.11-3.689-1.86-3.689-3.987 0-2.137 1.637-3.881 3.696-3.988l.2-.005h2.758l.103-.005A.82.82 0 008.86.903zM15.91.903h-2.736l-.103.005a.82.82 0 00-.013 1.627l.103.007h2.756l.2.005c2.063.111 3.689 1.861 3.689 3.988 0 2.136-1.637 3.88-3.696 3.988l-.2.005h-2.758l-.103.005a.82.82 0 00-.013 1.627l.103.007h2.766l.218-.004c2.966-.114 5.321-2.599 5.321-5.628 0-3.036-2.36-5.512-5.317-5.628L15.91.903z" />
        <path d="M6.97 5.715l-.103.005a.82.82 0 00-.014 1.628l.103.006h7.239l.103-.005a.82.82 0 00.013-1.627l-.103-.007H6.97z" />
      </g>
    </svg>
  );
};

const MemoSvgLink = React.memo(SvgLink);
export default MemoSvgLink;
