import * as React from 'react';

const SvgKonesHouse = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 38 32"
      {...props}
    >
      <path d="M36.754 12.669a.606.606 0 00.646.071.62.62 0 00.344-.558V8.875c0-.19-.086-.37-.233-.487L27.558.434a1.888 1.888 0 00-2.371 0l-3.746 2.994V2.261a.615.615 0 00-.611-.62h-2.609a.615.615 0 00-.611.62V6.49l-2.376 1.899a.624.624 0 00-.233.487v3.308c0 .238.134.454.345.558s.461.076.645-.071l.314-.251v6.043l-5.703-4.557a1.888 1.888 0 00-2.371 0L.234 20.297a.623.623 0 00-.233.487v3.308c0 .238.134.454.344.558a.6.6 0 00.646-.071l.315-.251v6.421H.613c-.338 0-.611.278-.611.62s.274.62.611.62h36.521c.338 0 .611-.278.611-.62s-.274-.62-.611-.62h-.693V12.421l.315.251zM18.831 2.88h1.386v1.525l-1.386 1.107V2.88zm-2.609 6.295l9.72-7.768a.683.683 0 01.858 0l9.72 7.768v1.728l-9.771-7.808a.603.603 0 00-.756 0l-9.771 7.808V9.175zM1.223 21.082l7.764-6.205a.683.683 0 01.858 0l6.46 5.162v1.729l-6.51-5.203a.606.606 0 00-.757 0L1.224 22.81v-1.729zm1.304 2.265l6.888-5.505 6.888 5.505v7.398h-2.69v-7.318a.615.615 0 00-.611-.62H5.828a.615.615 0 00-.611.62v7.318h-2.69v-7.398zm3.913 3.347h5.951V28.1H6.44v-1.406zm5.951-1.241H6.44v-1.406h5.951v1.406zM6.44 29.34h5.951v1.406H6.44V29.34zm17.69-3.223l-.041-.002c-.161 0-.319.066-.432.182s-.179.275-.179.438.065.323.179.438a.607.607 0 00.432.182l.041-.002v3.392h-2.69v-8.021h2.69v3.392zm11.086 4.628h-9.864v-8.641a.615.615 0 00-.611-.62h-3.913a.615.615 0 00-.611.62v8.641h-2.69V11.44l8.845-7.069 8.845 7.069v19.305z" />
      <path d="M28.002 28.017h3.913a.615.615 0 00.611-.62v-5.292a.615.615 0 00-.611-.62h-3.913a.615.615 0 00-.611.62v5.292c0 .343.274.62.611.62zm.611-1.241v-2.067h2.69v2.067h-2.69zm2.691-4.051v.744h-2.69v-.744h2.69zm-3.302-3.969h3.913a.615.615 0 00.611-.62v-5.292a.616.616 0 00-.611-.62h-3.913a.615.615 0 00-.611.62v5.292c0 .343.274.62.611.62zm.611-1.241v-2.067h2.69v2.067h-2.69zm2.691-4.051v.744h-2.69v-.744h2.69zm-6.563-1.241h-3.913a.615.615 0 00-.611.62v5.292c0 .343.274.62.611.62h3.913a.615.615 0 00.611-.62v-5.292a.616.616 0 00-.611-.62zm-.611 1.241v.744h-2.69v-.744h2.69zm-2.69 4.051v-2.067h2.69v2.067h-2.69z" />
    </svg>
  );
};

const MemoSvgKonesHouse = React.memo(SvgKonesHouse);
export default MemoSvgKonesHouse;
