import * as React from 'react';

const SvgDooron = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <mask
        id="dooron_svg__a"
        style={{
          maskType: 'alpha',
        }}
        maskUnits="userSpaceOnUse"
        x={2}
        y={2}
        width={20}
        height={20}
      >
        <path
          d="M11.998 2c5.521 0 9.998 4.474 9.998 9.993 0 5.52-4.477 9.993-9.998 9.993C6.476 21.986 2 17.512 2 11.993S6.476 2 11.998 2z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#dooron_svg__a)">
        <path
          d="M11.998 2c5.521 0 9.998 4.474 9.998 9.993 0 5.52-4.477 9.993-9.998 9.993C6.476 21.986 2 17.512 2 11.993S6.476 2 11.998 2z"
          fill="url(#dooron_svg__paint0_linear_760_66)"
        />
        <path
          d="M16.087 19.348c-.19.114-1.518.114-3.983 0 0 0-.99.376-2.492-.462-.292-.163-.715-.71-1.268-1.638.419-.227.67-.385.752-.476.48-.523.753-2.124.753-2.124l2.421.122v-.024l2.139-.131s.273 1.6.752 2.124c.165.181.684.409 1.024.633.644.427.315 1.728-.098 1.976z"
          fill="#FDCAA0"
        />
        <path
          d="M7.952 17.433l.383-.187s1.416 1.663 3.884 1.663c2.469 0 3.548-1.81 3.548-1.81l.328.149c-.923 1.292-2.215 1.938-3.876 1.938-1.66 0-3.083-.585-4.267-1.753z"
          fill="#D86000"
        />
        <path
          d="M15.767 17.098l3.142 1.65c.068.035.156.122.263.26a9.982 9.982 0 01-7.16 3.015 9.981 9.981 0 01-7.154-3.007c.117-.137.218-.226.302-.269.492-.245 3.175-1.5 3.175-1.5s1.416 1.662 3.884 1.662c2.469 0 3.548-1.81 3.548-1.81z"
          fill="#fff"
        />
        <path
          d="M7.952 17.433l.383-.187s1.416 1.663 3.884 1.663c2.469 0 3.548-1.81 3.548-1.81l.328.149c-.923 1.292-2.215 1.938-3.876 1.938-1.66 0-3.083-.585-4.267-1.753z"
          fill="#D86000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.612 15.495c1.05.572 1.835.841 2.353.807 1.353-.089 2.664-1.145 2.681-.993.036.314.061.619.029.655-.514.58-1.476 1.194-2.469 1.267-.993.074-2.686-1.067-2.686-1.067l.092-.67z"
          fill="#F1BE94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.336 14.263c-.297 1.177-2.438 1.667-3.228 1.635-.79-.033-1.384.032-2.504-.785-1.12-.818-1.185-1.962-1.185-1.962s-.04-.364-.076-.968c-.076-.006-.159-2.2-.066-2.104-.002-.82.025-1.741.109-2.683.263-2.975 3.92-2.517 3.92-2.517 3.293.196 3.326 1.929 3.49 2.812.067.356.053 1.306-.007 2.388.082-.086.045 1.738.006 2.117a78.558 78.558 0 01-.46 2.067z"
          fill="#FDCAA0"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.336 14.263c-.28 1.11-2.2 1.61-3.081 1.635h-.148l.198-11.02.213.016c3.083.257 3.117 1.933 3.279 2.797.066.356.052 1.305-.008 2.388.082-.086.045 1.738.006 2.117a78.881 78.881 0 01-.46 2.067z"
          fill="#F1BE94"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.834 9.797a.438.438 0 110 .877.438.438 0 010-.877z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.612 5.869c1.066.284 1.858.41 2.376.376 1.353-.09 2.641-.714 2.659-.562.03.276.31.66.294.746-.002.012-1.729.434-2.722.508-.993.073-2.999-.416-2.999-.416l.392-.652z"
          fill="#000"
          fillOpacity={0.03}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.35 9.797a.438.438 0 11.001.876.438.438 0 010-.876z"
          fill="#000"
        />
        <path
          d="M10.88 13.972h2.285l-.37.323c-.49.196-.89.242-1.199.139-.31-.103-.548-.257-.715-.462z"
          fill="#fff"
        />
        <path
          d="M12.057 14.434c.755 0 1.097-.47 1.097-.47l.128-.04s-.209.708-1.225.74c-.476.016-.907-.23-1.291-.738l.161.046c.308.308.684.462 1.13.462z"
          fill="#E18055"
        />
        <path
          d="M12.034 14.018c.032 0 .246-.023.277-.023h.969s-.06-.103-.365-.187a3.014 3.014 0 00-.558-.09c-.075-.005-.166.026-.253.024-.142-.005-.303-.038-.439-.024-.215.023-.515.116-.9.277h1.062c.066 0 .14.023.207.023z"
          fill="#E18055"
        />
        <path
          d="M13.125 8.791c-.053.094-.032.203.002.309a.391.391 0 01-.074-.299c-.139.066.006.454.006.454.255.012.918-.125 1.409-.035.575.107.811.19.811.19s-.056-.353-.419-.48c-.733-.258-1.189-.16-1.735-.139z"
          fill="url(#dooron_svg__paint1_linear_760_66)"
        />
        <path
          d="M11.095 9.1c.034-.106.056-.215.002-.309-.545-.021-1.001-.119-1.735.139-.362.127-.419.48-.419.48s.237-.083.812-.19c.49-.09 1.154.047 1.409.035 0 0 .144-.388.005-.454a.392.392 0 01-.074.299z"
          fill="url(#dooron_svg__paint2_linear_760_66)"
        />
        <path
          d="M16.377 9.377c-.135-.008-.398.353-.398.353-.156 1.28-.201 2.253-.201 2.253.508 0 .608-.549.807-1.032.303-.74.483-1.532-.208-1.574zM7.646 9.377c.135-.008.398.353.398.353.156 1.28.201 2.253.201 2.253-.508 0-.608-.549-.807-1.032-.303-.74-.483-1.532.208-1.574z"
          fill="#FDCAA0"
        />
        <path
          d="M8.044 11.85s.248-2.573.273-1.958c.079 1.974.094 3.949.81 3.967.205.006.243-.422.717-.728.098-.062.876-.589 2.26-.589s2.132.574 2.203.623c.32.222.588.697.737.694.467-.007.718-1.346.75-4.016l.183 1.477s.012.288-.02.73c-.003.345-.03 1.443-.258 1.994-.273.66-.69 1.018-.984 1.31-.254.254-1.02.88-1.223 1.046l-.008.009-.016.01-.026.022.007-.01c-.132.08-.628.347-1.293.332-.94-.021-1.33-.275-1.632-.53-.168-.118-.92-.648-1.173-.906-.287-.292-.768-.749-1.035-1.406a2.433 2.433 0 01-.113-.382 3.928 3.928 0 01-.103-.675 11.39 11.39 0 01-.056-.99l.001.01-.001-.033zm4.014 1.407c-1.22-.002-2.043.556-1.98.897.1.527.581.957.847 1.14.385.264.537-.364 1.137-.364.588 0 .73.591 1.06.379.403-.26.824-.755.902-1.244.075-.47-1.155-.806-1.966-.808z"
          fill="#424141"
        />
        <path
          d="M15.98 11.342c.303-2.782.375-4.576.216-5.384-.24-1.21-.88-1.695-1.359-1.776-.479-.08-1.438-.726-2.156-.726-.72 0-1.04-.162-1.678 0-.403.101-.972.083-1.707-.056a.08.08 0 00-.094.09.35.35 0 01-.037.207l-.832-.129a.24.24 0 00-.269.174c-.192.699-.118 1.195.222 1.49 0 0-.479.888-.479 1.614l.26 5.004c.152-1.721.388-2.905.708-3.55-.16-.915.31-1.711.95-1.926 0 0 1.677.323 2.316.242 0 0 1.918-.08 2.317-.242.32 0 .763.634.87 1.441v.449c0 .023.01.044.026.06.274.249.487 1.393.656 2.719.014.11.037.21.07.3z"
          fill="#545351"
        />
        <path
          d="M10.253 3.517c-.28-.01-.599-.05-.957-.117a.08.08 0 00-.094.09.35.35 0 01-.037.207s.08.62.424 1.048c.709.883 2.022 1.925 2.452 1.87 0 0-.965-.776-1.506-1.685-.226-.38-.196-1.41-.282-1.413z"
          fill="url(#dooron_svg__paint3_linear_760_66)"
        />
        <path
          d="M15.98 11.343c.303-2.783.376-4.577.216-5.385a4.721 4.721 0 00-.263-.811c.076.168-.07.396-.438.682-.253.198-.631.38-1.133.545a.38.38 0 01.062.008c.312.077.705.68.804 1.433v.449c0 .023.01.044.026.06.274.249.487 1.393.656 2.719.014.11.037.21.07.3z"
          fill="url(#dooron_svg__paint4_linear_760_66)"
        />
        <path
          d="M8.286 5.232c-.32.592-.479 1.13-.479 1.614l.26 5.004c.152-1.722.388-2.905.708-3.55-.16-.915.31-1.711.95-1.926 0 0-.518-.09-.943-.43a1.485 1.485 0 01-.496-.712z"
          fill="url(#dooron_svg__paint5_linear_760_66)"
        />
      </g>
      <defs>
        <linearGradient
          id="dooron_svg__paint0_linear_760_66"
          x1={11.998}
          y1={31.98}
          x2={31.984}
          y2={11.984}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7100" />
          <stop offset={1} stopColor="#F6DA35" />
        </linearGradient>
        <linearGradient
          id="dooron_svg__paint1_linear_760_66"
          x1={15.155}
          y1={9.089}
          x2={12.999}
          y2={8.97}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F3C3B" />
          <stop offset={1} stopColor="#38312D" />
        </linearGradient>
        <linearGradient
          id="dooron_svg__paint2_linear_760_66"
          x1={11.106}
          y1={9.089}
          x2={8.949}
          y2={8.97}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#3F3C3B" />
          <stop offset={1} stopColor="#38312D" />
        </linearGradient>
        <linearGradient
          id="dooron_svg__paint3_linear_760_66"
          x1={9.455}
          y1={3.399}
          x2={10.822}
          y2={6.779}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#434240" />
          <stop offset={1} stopColor="#545351" />
        </linearGradient>
        <linearGradient
          id="dooron_svg__paint4_linear_760_66"
          x1={15.322}
          y1={5.302}
          x2={15.285}
          y2={11.842}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444" />
          <stop offset={1} stopColor="#30241E" />
        </linearGradient>
        <linearGradient
          id="dooron_svg__paint5_linear_760_66"
          x1={8.767}
          y1={5.397}
          x2={8.73}
          y2={12.383}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#444" />
          <stop offset={1} stopColor="#30241E" />
        </linearGradient>
      </defs>
    </svg>
  );
};

const MemoSvgDooron = React.memo(SvgDooron);
export default MemoSvgDooron;
