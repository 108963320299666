import * as React from 'react';

const SvgWeight = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M924 767l-60-59 57-56q5-5 5-11.5t-5-10.5l-63-63 52-52q5-5 5-11t-5-11l-70-70q-2-2-4.5-3t-6.5-1q-3 0-5.5 1t-4.5 3L667 574 451 358l152-151q4-5 4-11t-4-11l-71-70q-2-2-4.5-3.5T522 110q-4 0-6.5 1.5T511 115l-52 52-63-63q-2-2-5-3t-6-1q-3 0-5.5 1t-5.5 3l-57 56-59-60q-3-2-5.5-3t-5.5-1q-3 0-6 1t-5 3L100 235q-4 5-4 11t4 11l60 59-57 56q-5 5-5 11.5t5 10.5l63 63-52 52q-5 4-5 10.5t5 11.5l70 70q2 2 5.5 3t5.5 1q3 0 6-1t5-3l152-152 216 216-152 152q-5 5-5 11t5 11l70 70q2 2 5 3t6 1q3 0 5.5-1t5.5-3l51-52 63 63q2 2 5 3t6 1q3 0 6-1t5-3l57-57 59 60q2 2 5 3t6 1q3 0 5.5-1t5.5-3l135-135q5-4 5-10t-4-11zM133 246l114-113 48 48-113 113-49-48zm60 81l135-134 57-57 48 49-249 247-48-48zm2 242l-48-49 374-373 48 49-374 373zm187-139l48-48 214 214-48 48-214-214zm121 447l-48-49 152-151 222-222 48 49-374 373zm137 11l-49-48 249-248 48 48-56 57-136 134-56 57zm137 3l-48-48 113-113 49 48z" />
    </svg>
  );
};

const MemoSvgWeight = React.memo(SvgWeight);
export default MemoSvgWeight;
