import * as React from 'react';

const SvgAdultSafety = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <image
        x={287}
        y={2112}
        width={35}
        height={35}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAANqADAAQAAAABAAAANgAAAADzQy6kAAADjUlEQVRoBe1ZjVHdMAx+r8cCXYEV6AisQEdgBVZgBVZgBToCjEBHgBFevy9ngS3JiePI9PUa3xknsqx/S8rjcNjHboHdArsFdgt8kQVOp9Ml5gPmK6YMPj9iXn+RGLFsIPi9aDKzUsHvsZwHUoOwFLh1PAPx/JWDkLetGmV4jwPtvJ00LY/5lgm85vFquwSfFL59PoY8MSH0htVtiASJyAjFeuU7a4/1eovG2HLWGDPaY++Gw18CRCv2skGPXxvOjj2asuKaTJjjnncnAklbOo5cIT4/jTV5EHUIym6idbDuXQaxHksGgrJQt7RVbIj/DaVyk0HoG8wnTD3o0dCCnPPl81EDRr1DEfHM+/F4PJuyMErfYXTDPQbPSAehV62EeG1ao73YrVhSgPeE9YdhJqGmFVjz/gMKbinya3hZXCjFpND7eYKj7riznA4HYIY2xx6PCQZGPR+SriYZ0C3Q2L9OOEMzKK235UMy06N4dAu0wysi1H2HgdldIVLMy43HDaR1/Xvw8EJgYLamVWpR2xUWBz0DvoUooYmAGX8jjBxsp6QkfLAD7GqGSXMiufiguPzghszysQPr1G9MqVtUhvNnpXa5XgQ+B2WILQewYmsYEo+fLsxoxiOTeJU/wOevxnPjtXK0DwxOLWFIoZpDxZME51u+5eKyIxjO1S5+noQxAy2WlDnPuYXcM9QiDIx06gVo6jx6710LT4Zy/s8M8uR4XjzcggBCtKAevEdVL2GPZ+hlelOEc5MCcWtycA/Tu9tV3jVaBg7COgzJyBUGcKZq78t5LrWz82BEuD/mAE7ldMRsb7EU0ZqAZD53L9ykgjPaGzSKMRphCtftLY1XaoBEEMs0aj0dvSThllCLxb3swKgZgnyM9wBjZuaeDGOAmh4GDgr8PJFh3I8NKpUzE1xZXctiU7p2wdNrTbn8nJHHKFADgJvcF5OJsKct6AlnLjmQGFZzxhA6xDEhDJjct/7/qYGIhJgXGvp+iECyuqUgE0zw5lZzp4EsvWR/F5I4et7yuu9cwFpqXzqX05Dnex1R2Ji8puHN74lycfkBWwolY2UyxDmxdCK7aikSBU5Od79ZEY0IAgy34p7gXdc1gIph7kVSbCl0CyLqxTOuiSQtf/UdxM09AUwSiuI9vRYCCGHs1FK7R8ODGSWA5BpQeK5eQbCW0XpTu6eIByvCcbXg+4HdArsFdgv81xb4Ay4YSwlL4VFCAAAAAElFTkSuQmCC"
        transform="translate(-291 -2115)"
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

const MemoSvgAdultSafety = React.memo(SvgAdultSafety);
export default MemoSvgAdultSafety;
