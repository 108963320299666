import * as React from 'react';

const SvgMobile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M20.889 25.6v-2.4h-9.778v2.4h3.667c0-.663.543-1.2 1.222-1.2.675 0 1.222.539 1.222 1.199l3.667.001zm0-14.4h-9.778v9.6h9.778v-9.6zm0-2.4V6.4h-9.778v2.4h9.778zM9.892 4h12.216a1.21 1.21 0 011.225 1.198v21.603c0 .662-.548 1.198-1.225 1.198H9.892a1.21 1.21 0 01-1.225-1.198V5.198C8.667 4.536 9.215 4 9.892 4z" />
    </svg>
  );
};

const MemoSvgMobile = React.memo(SvgMobile);
export default MemoSvgMobile;
