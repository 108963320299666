import * as React from 'react';

const SvgAppleLogo = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M28.305 24.518a16.566 16.566 0 01-1.64 2.948c-.862 1.229-1.568 2.08-2.112 2.552-.843.776-1.747 1.173-2.714 1.195-.695 0-1.532-.198-2.507-.599-.978-.399-1.877-.597-2.699-.597-.862 0-1.787.198-2.776.597-.991.401-1.788.61-2.399.631-.928.04-1.853-.369-2.776-1.227-.589-.514-1.326-1.395-2.209-2.643-.947-1.333-1.726-2.878-2.336-4.64-.653-1.903-.981-3.746-.981-5.53 0-2.044.442-3.806 1.326-5.283.695-1.186 1.62-2.122 2.777-2.809s2.408-1.037 3.755-1.06c.737 0 1.703.228 2.904.676 1.198.45 1.966.677 2.304.677.252 0 1.106-.267 2.554-.798 1.369-.493 2.525-.697 3.472-.616 2.566.207 4.493 1.218 5.775 3.041-2.295 1.39-3.43 3.338-3.407 5.836.021 1.946.727 3.565 2.114 4.851a6.936 6.936 0 002.112 1.385c-.169.491-.348.962-.538 1.414zM22.421 1.363c0 1.525-.557 2.949-1.668 4.267-1.34 1.567-2.961 2.472-4.719 2.329a4.839 4.839 0 01-.035-.578c0-1.464.637-3.031 1.769-4.312.565-.649 1.284-1.188 2.155-1.618.87-.424 1.692-.658 2.466-.698.023.204.032.408.032.61z" />
    </svg>
  );
};

const MemoSvgAppleLogo = React.memo(SvgAppleLogo);
export default MemoSvgAppleLogo;
