import * as React from 'react';

const SvgChassis = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 17"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M15.63 2h5.906c.89 0 1.747.32 2.419.894l.196.18 3.633 3.595c.407.403.656.936.707 1.502l.009.214v1.993c0 .292-.12.575-.333.784a1.19 1.19 0 01-.68.328l-.155.01H2.668c-.316 0-.617-.122-.835-.337-.179-.175-.29-.4-.323-.638l-.01-.145V7.82c0-.219.13-.413.323-.5l.102-.033 6.162-1.397a4.109 4.109 0 001.279-.53l.23-.156 3.079-2.24a5.022 5.022 0 012.638-.953l.318-.01z"
          fill="#FFF"
        />
        <path
          d="M24.036 4.5c.89 0 1.747.32 2.419.894l.196.18 3.348 3.313L30 10.38a2.601 2.601 0 01-.783 1.853c-.5.49-1.178.767-1.885.767H4.007L4 12.88V10.32c0-.219.13-.413.323-.5l.102-.033 6.162-1.397a4.109 4.109 0 001.279-.53l.23-.156 3.079-2.24a5.022 5.022 0 012.638-.953l.318-.01h5.905z"
          fill="#8DCED6"
        />
        <path
          d="M21.536.5l.294.008a5.217 5.217 0 013.375 1.5l3.634 3.595.179.189c.63.713.982 1.635.982 2.593v1.995l-.01.23a2.604 2.604 0 01-.773 1.623c-.5.49-1.178.767-1.885.767H2.668l-.235-.01a2.689 2.689 0 01-1.652-.757A2.597 2.597 0 010 10.38V7.82l.007-.177a2.046 2.046 0 011.587-1.82l6.16-1.397.206-.055c.27-.085.524-.213.754-.38l3.078-2.241.283-.196A6.522 6.522 0 0115.631.5h5.905zm0 1.5h-5.905l-.318.01c-.95.06-1.865.39-2.638.952L9.597 5.203l-.231.157a4.109 4.109 0 01-1.28.53l-6.16 1.396-.103.034a.546.546 0 00-.323.499v2.561l.01.145c.032.238.144.463.323.638.218.215.52.337.835.337h24.664l.156-.01a1.19 1.19 0 00.68-.328c.212-.209.332-.492.332-.784V8.385l-.01-.214a2.413 2.413 0 00-.706-1.502l-3.633-3.594-.196-.18A3.717 3.717 0 0021.536 2z"
          fill="#363636"
        />
        <path
          d="M22.5 9.25a3.748 3.748 0 00-2.652 6.397 3.755 3.755 0 002.399 1.094l.253.009a3.751 3.751 0 100-7.5z"
          fill="#363636"
        />
        <path
          d="M22.5 10.75a2.25 2.25 0 01.222 4.489l-.222.011A2.256 2.256 0 0120.25 13a2.248 2.248 0 012.25-2.25z"
          fill="#FFF"
        />
        <path
          d="M7.5 9.25a3.748 3.748 0 00-2.652 6.397 3.755 3.755 0 002.399 1.094l.253.009a3.751 3.751 0 100-7.5z"
          fill="#363636"
        />
        <path
          d="M7.5 10.75a2.25 2.25 0 01.222 4.489l-.222.011A2.256 2.256 0 015.25 13a2.248 2.248 0 012.25-2.25z"
          fill="#FFF"
        />
      </g>
    </svg>
  );
};

const MemoSvgChassis = React.memo(SvgChassis);
export default MemoSvgChassis;
