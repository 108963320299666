import * as React from 'react';

const SvgChildrenSafety = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 28 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <image
        transform="matrix(-1 0 0 1 219 -2115)"
        x={187}
        y={2112}
        width={35}
        height={35}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAANqADAAQAAAABAAAANgAAAADzQy6kAAADlUlEQVRoBe2ZgVXcMAyGOV4XoCNcR6AjsAKMQEegI9ARYAQYgY5QRqAj0BGu/5dnu67r2HLiS+71Re/lnNiyrF+SZSV3drbRZoHNApsF1rLA4XC41PWk610X9KbrXtfFWjrNXlfK3+oaIwDuZy+ytAApjadq9La0XrPXE6KXGio3fj17sUYB5438Kftl2jHyfDXSf7TuucCsycHK1w3oh5okhRJhhGded7vdc8L/S88WpX8m89Z9FKg047GnAhDdP+iykDVklwEsjXPJ4cWvrvELXf7sGgP45PlPpi0ofeeVFA8pn/MqR6uB2nkFcy2a5vpd3yftubB3xMpeJPsRqvQ/a/xV7elRzgVR38PpafxHozkeIyPiNdqBnNfwnPfa40l6TYrWEsOw18RHEsklGnUPxXHIpM4G6zZSaiwpoDD0Aw3VpsfCMBj9hEy6LiK3OopHyo3d7jWAx2q0aFlVK6lC1itY+srts7QqSaeEIyIdOMZzDVhIDIXFvSdqwK7k0n1BTtehGjCTx5xG39XWDLHY60sNmOWAZX/tjeF4MsAsHsNh1nCk/Fok9Rc9Ji8ArBZeABuqd/FbwtEbgXlHoyIwt6olHPeRho/Rfe62CEweJcnwhYsDn+OGlue+rz5OqJoivXsE4qp94Am8yRze7WqVDiBjI3oR7a0EYUELhQXFXKtY/KFOxWIpAuL1AX/bjiQzQ4JqlmThsJju7+goEJa3yCyIOMx/u5B0vvLWKLxUihGPLEHzqhlpWCtyAYEHQirXfWuIISMm5NVC+p/9mgm48S4tYClyUSoOR4sxmJMSgMJ3f90jZzRsx7U2jki4JRyH1xhEih9jjCqksZT+AhSrJUZkATaVN/91SEKt2bHVayhLsglhHIOK7+FxvBg5eDXm8ffFTwOeybcSxh8MIa37/qSlUvnsqhY8x4FMBovnwUOVwgef7FuB5gGUQ9nPG+aI31IJaVoDaTHrvmHTe4WGFfTMwX1Nm1tS/XiDcbxRShrz03yqgFs8jXN1Z6kaYprly6fWDHqf6pY+N4Uik6UMe6jVaoSdL6h9iGU9xxpG+lgKy2ZgLCpwlr1m1G8y283Y/kTi+USxXybO6zmt6PFJwGSpIaP11LK3rEnAnBJ4rX/q7YRwMjC3cW866dFdzGRgaOJC8mt3rWwCi99jZgFz4L6pzVYPNv0mcx0XmANHSC4Nrghssrm2iZsFNgtsFvgvLfAbP+at9Bb1tRsAAAAASUVORK5CYII="
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

const MemoSvgChildrenSafety = React.memo(SvgChildrenSafety);
export default MemoSvgChildrenSafety;
