import { FieldEnums } from '../services/search/consts';

export const stripNumbers = (string: string) =>
  Number(string.replace(/[^\d]/g, ''));

export const toLocalNis = (value: number) =>
  value ? `₪ ${value.toLocaleString()}` : '';

type ArrayToString = (
  value: string | string[] | undefined,
) => string | undefined;

export const arrayToString: ArrayToString = (value) =>
  typeof value === 'string' ? value : value?.join(',');

type MinLimit = number;
type MaxLimit = number;
type IsInRange = (value: number, range: [MinLimit, MaxLimit]) => boolean;

export const isInRange: IsInRange = (value, [min, max]) =>
  value >= min && value <= max;

export const getNumbersArray = (commaStringArray: string) =>
  commaStringArray ? commaStringArray.split(',').map((id) => Number(id)) : [];

/* eslint-disable no-magic-numbers */
export const roundToOneDecimalPlace = (number: string | number) =>
  Math.round((typeof number === 'string' ? parseFloat(number) : number) * 10) /
  10;
