import * as React from 'react';

const SvgDoorsNumber = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 22 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M1.384 21.929c0 1.178.958 2.143 2.093 2.143h8.124c.319 0 .603-.215.674-.536.745-3.536 3.512-6.357 6.988-7.107a.687.687 0 00.532-.679v-.964c0-3.429-1.312-6.679-3.725-9.107-2.376-2.429-5.604-3.75-9.01-3.75H3.512c-1.17 0-2.128.964-2.128 2.143v17.857z"
          fill="#FFF"
        />
        <path
          d="M7.262 4.429c-1.17 0-2.128.964-2.128 2.143v17.857c0 .318.07.62.194.892h7.523c.319 0 .603-.214.674-.535.745-3.536 3.512-6.357 6.988-7.107a.687.687 0 00.532-.679v-.964c0-3.429-1.312-6.679-3.725-9.107a12.411 12.411 0 00-2.103-1.724 12.785 12.785 0 00-4.407-.776H7.262z"
          fill="#8DCED6"
        />
        <path
          d="M4.754 3.714h2.661c3.228 0 6.634 2.107 7.946 5 .532 1.179-.355 2.5-1.632 2.5H5.11A1.952 1.952 0 013.158 9.25V5.32c0-.892.71-1.607 1.596-1.607z"
          fill="#363636"
        />
        <path
          d="M4.577 9.25c0 .286.248.536.532.536h8.62a.354.354 0 00.32-.179.348.348 0 00.035-.321c-1.1-2.357-3.938-4.143-6.67-4.143H4.862c-.107 0-.284.072-.284.179V9.25z"
          fill="#FFF"
        />
        <path
          d="M4.222 12.643h.355a.714.714 0 010 1.428h-.355a.714.714 0 010-1.428z"
          fill="#363636"
        />
        <path
          d="M3.477.5H7.06c7.84 0 14.19 6.393 14.19 14.286v.964c0 1-.674 1.857-1.632 2.072-2.944.642-5.286 3-5.924 6-.213.964-1.064 1.678-2.058 1.678H3.513C1.56 25.5 0 23.893 0 21.929V4.07C-.035 2.107 1.526.5 3.477.5zM1.384 21.929c0 1.178.958 2.143 2.093 2.143h8.124c.319 0 .603-.215.674-.536.745-3.536 3.512-6.357 6.988-7.107a.687.687 0 00.532-.679v-.964c0-3.429-1.312-6.679-3.725-9.107-2.376-2.429-5.604-3.75-9.01-3.75H3.512c-1.17 0-2.128.964-2.128 2.143v17.857z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgDoorsNumber = React.memo(SvgDoorsNumber);
export default MemoSvgDoorsNumber;
