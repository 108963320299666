import * as React from 'react';

const SvgRepairs = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M706 588l-63 64-55-56 108-111q3-3 7-5t9-2q32-2 81.5-12.5T872 427q37-38 50-89.5T920 234l-9-28-101 102-72-19-20-74 101-103-28-8q-50-15-101-2t-88 51q-28 28-38 78.5T552 315q-1 5-2.5 9t-4.5 7L436 442 269 272l61-61-159-99-75 76 97 162 52-53 167 170-271 276q-31 31-31 76.5t31 76.5q15 16 34.5 24t40.5 8q22 0 41.5-8t34.5-24l271-276 56 57-64 64 153 155q15 16 34.5 24t40.5 8q22 0 41.5-8t34.5-24q31-31 31-76.5T859 743L706 588zM140 193l36-37 99 61-75 76zm127 678q-20 21-50.5 21T165 871t-21-51.5 21-52.5l405-411q8-8 12-18t5-21q3-49 13.5-86t26.5-54q22-22 50.5-34t59.5-12h7.5l6.5 1-71 73 30 113 111 30 71-72q3 34-8.5 67.5T847 402q-23 24-71 32t-66 9q-11 1-21 5t-17 12L267 871zm568 0q-21 21-51.5 21T732 871L604 741l102-104 128 131q22 21 22 51.5T835 871z" />
    </svg>
  );
};

const MemoSvgRepairs = React.memo(SvgRepairs);
export default MemoSvgRepairs;
