import * as React from 'react';

const SvgDoor = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M9.867 4h12c1.031 0 1.867.836 1.867 1.867v21.334a1.867 1.867 0 01-1.867 1.867h-12A1.867 1.867 0 018 27.201V5.868c0-1.031.836-1.867 1.867-1.867zm0 1.867V27.2h12V5.867h-12zm9.157 12.8c.558 0 1.011.418 1.011.933s-.453.933-1.011.933h-2.013c-.558 0-1.011-.418-1.011-.933s.453-.933 1.011-.933h2.013z" />
    </svg>
  );
};

const MemoSvgDoor = React.memo(SvgDoor);
export default MemoSvgDoor;
