import * as React from 'react';

const SvgManagershover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M8.727 24.357c0-.316-.907-1.045-.695-2.947.073-.647.547-1.953 1.424-3.916l11.763-.905 26.02.826L49.453 16 52 19.892l-2.547 5.1c-27.15.054-40.726-.158-40.726-.635z"
          fill="#FFAB00"
        />
        <g transform="translate(8 12)">
          <ellipse fill="#FFF" cx={9.693} cy={12.444} rx={3.67} ry={3.556} />
          <ellipse fill="#FFF" cx={32.579} cy={12.444} rx={3.67} ry={3.556} />
          <g stroke="#000" strokeWidth={1.2}>
            <path d="M11.498 4.285l4.97.6a4.8 4.8 0 00.576.035h22.192" />
            <path strokeLinecap="square" d="M24.918.487l-.222 3.798" />
            <path d="M42.356 3.448c-2.362.371-3.383 1.234-3.063 2.589.32 1.354 1.779 1.712 4.374 1.074" />
            <path
              d="M39.903 13.052h.878a2.401 2.401 0 002.348-1.838l.779-2.142c.21-.897.058-1.84-.424-2.63l-2.19-3.586C39.987.716 37.524 0 34.988 0h-13.64a7.316 7.316 0 00-3.728 1.019l-6.574 3.368-7.755.684C1.428 5.235 0 6.771 0 8.612v2.07c0 1.309 1.079 2.37 2.41 2.37h3.522m10.559 0h12.387"
              strokeLinecap="round"
            />
            <path d="M32.953 0l.773 4.92h-.153" />
            <ellipse
              strokeLinejoin="round"
              cx={9.526}
              cy={12.414}
              rx={3.67}
              ry={3.556}
            />
            <ellipse
              strokeLinejoin="round"
              cx={33.058}
              cy={12.414}
              rx={3.67}
              ry={3.556}
            />
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M20.461 7.924h2.409"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgManagershover = React.memo(SvgManagershover);
export default MemoSvgManagershover;
