import * as React from 'react';

const PlusIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M16 8c.736 0 1.333.597 1.333 1.333v5.333h5.333c.684 0 1.247.515 1.324 1.178l.009.155c0 .736-.597 1.333-1.333 1.333h-5.333v5.333c0 .684-.515 1.247-1.178 1.324l-.155.009a1.333 1.333 0 01-1.333-1.333v-5.333H9.334a1.333 1.333 0 01-1.324-1.178l-.009-.155c0-.736.597-1.333 1.333-1.333h5.333V9.333c0-.684.515-1.247 1.178-1.324z" />
    </svg>
  );
};

export default PlusIcon;
