import * as React from 'react';

const SvgDrillingMachine = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M727 350l-25-26-46 48-2 3 25 26 45-48zm-192-96h-3v37h3q29 0 49.5 21.5T605 364q0 31-20.5 52.5T535 438h-3v37h3q44 0 75-32.5t31-78.5q0-45-31-77.5T535 254zM49 224v280h40L32 669v131h248V650h-58l52-146h234V224H49zm424 244H249l-78 218h74v77H67v-87l73-208H84V261h389v207zm320-118l-25-26-45 48-3 3 25 26 46-48zm199 0l-25-26-45 48-3 3 25 26 46-48zm-66 0l-25-26-46 48-2 3 25 26 46-48zm-66 0l-25-26-46 48-2 3 25 26 45-48z" />
    </svg>
  );
};

const MemoSvgDrillingMachine = React.memo(SvgDrillingMachine);
export default MemoSvgDrillingMachine;
