import * as React from 'react';

const SvgSpecialSearch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 73 73"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <linearGradient
          x1="0%"
          y1="50%"
          x2="100%"
          y2="50%"
          id="specialSearch_svg__c"
        >
          <stop stopColor="#FF7100" offset="0%" />
          <stop stopColor="#F6DA35" offset="100%" />
        </linearGradient>
        <filter
          x="-76.2%"
          y="-20.8%"
          width="252.3%"
          height="141.5%"
          filterUnits="objectBoundingBox"
          id="specialSearch_svg__b"
        >
          <feOffset dx={3} in="SourceAlpha" result="shadowOffsetInner1" />
          <feComposite
            in="shadowOffsetInner1"
            in2="SourceAlpha"
            operator="arithmetic"
            k2={-1}
            k3={1}
            result="shadowInnerInner1"
          />
          <feColorMatrix
            values="0 0 0 0 0.854901961 0 0 0 0 0.854901961 0 0 0 0 0.854901961 0 0 0 1 0"
            in="shadowInnerInner1"
          />
        </filter>
        <path d="M1.97 0v7.221H0V0h1.97z" id="specialSearch_svg__a" />
      </defs>
      <g transform="translate(-8.265 -7.055)" fill="none" fillRule="evenodd">
        <circle fill="#F8F8F8" cx={40.177} cy={39.389} r={31.511} />
        <g fillRule="nonzero">
          <path
            stroke="#363636"
            strokeWidth={0.857}
            fill="#F8F8F8"
            d="M52.594 54.83l3.25-3.248 5.105 5.106-3.249 3.249z"
          />
          <g transform="rotate(-45 92.484 -36.071)">
            <use fill="#F8F8F8" xlinkHref="#specialSearch_svg__a" />
            <use
              fill="#000"
              filter="url(#specialSearch_svg__b)"
              xlinkHref="#specialSearch_svg__a"
            />
          </g>
          <path
            stroke="#363636"
            d="M52.594 54.83l3.25-3.248 5.105 5.106-3.249 3.249z"
          />
        </g>
        <path
          d="M56.505 59.275l3.894-3.893a1.471 1.471 0 012.044-.036l16.261 15.165a4.963 4.963 0 011.418 4.88 5.04 5.04 0 01-3.608 3.607 4.963 4.963 0 01-4.88-1.418L56.47 61.32a1.471 1.471 0 01.036-2.045z"
          stroke="#363636"
          strokeWidth={0.857}
          fill="#EEE"
        />
        <path
          d="M59.354 56.924c.01.307.114.611.315.866l.08.093 15.165 16.26a4.963 4.963 0 005.3 1.29A5.04 5.04 0 0176.51 79.5a4.963 4.963 0 01-4.879-1.418L56.466 61.822a1.471 1.471 0 01.036-2.044l2.852-2.854z"
          fillOpacity={0.1}
          fill="#363636"
        />
        <path
          fill="url(#specialSearch_svg__c)"
          transform="scale(1 -1) rotate(45 132.523 31.303)"
          d="M13.13 8.534h9.19l-.206 5.909h-8.82z"
        />
        <path
          d="M56.505 59.275l3.894-3.893a1.471 1.471 0 012.044-.036l16.261 15.165a4.963 4.963 0 011.418 4.88 5.04 5.04 0 01-3.608 3.607 4.963 4.963 0 01-4.88-1.418L56.47 61.32a1.471 1.471 0 01.036-2.045z"
          stroke="#363636"
        />
        <path
          d="M55.33 54.317c8.332-8.332 8.291-21.882-.091-30.264-8.383-8.383-21.933-8.424-30.265-.092-8.332 8.332-8.291 21.882.091 30.265 8.383 8.382 21.933 8.423 30.265.091z"
          fill="#F8F8F8"
          fillRule="nonzero"
        />
        <g fillRule="nonzero">
          <path
            d="M56.383 52.989c-.283.33-.577.654-.883.971-8.235 8.528-21.782 8.805-30.259.62-8.476-8.186-8.672-21.734-.437-30.262.307-.317.62-.623.94-.917-7.324 8.557-6.84 21.409 1.321 29.29 8.056 7.78 20.692 7.915 28.995.588l.323-.29z"
            fill="#DCDCDC"
          />
          <path
            d="M25.241 54.58c8.477 8.185 22.024 7.908 30.259-.62 8.235-8.527 8.039-22.076-.437-30.261-8.476-8.186-22.024-7.908-30.259.62-8.235 8.527-8.039 22.075.437 30.26z"
            stroke="#363636"
          />
          <path
            d="M28.429 27.984c-6.228 6.45-6.017 16.757.471 23.022 6.489 6.266 16.797 6.117 23.025-.332 6.228-6.45 6.017-16.757-.471-23.023-6.488-6.265-16.797-6.116-23.025.333z"
            fill="#FFF"
            style={{
              mixBlendMode: 'multiply',
            }}
          />
          <path
            d="M28.907 35.697a11.86 11.86 0 003.086 12.106"
            stroke="#363636"
            style={{
              mixBlendMode: 'multiply',
            }}
            strokeLinecap="round"
          />
        </g>
        <path
          d="M28.863 27.639c6.378-6.378 16.688-6.41 23.028-.07s6.308 16.65-.07 23.027a16.571 16.571 0 01-1.737 1.512c4.823-6.393 4.349-15.498-1.443-21.29-5.791-5.791-14.896-6.266-21.29-1.442.457-.605.96-1.186 1.512-1.737z"
          fill="#EEE"
          fillRule="nonzero"
        />
        <path
          d="M28.794 50.666c6.34 6.34 16.649 6.308 23.027-.07s6.41-16.687.07-23.027-16.65-6.308-23.028.07-6.409 16.687-.07 23.027z"
          stroke="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgSpecialSearch = React.memo(SvgSpecialSearch);
export default MemoSvgSpecialSearch;
