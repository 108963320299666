import * as React from 'react';

const SvgCharity = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.224 3.218c-.227.09-.407.214-.511.339a.387.387 0 00-.094.361c.038.186.199.524.72 1.005.41.376 1.008.81 1.858 1.299-.1-1.126-.335-1.939-.653-2.442-.187-.294-.383-.454-.573-.533-.18-.075-.419-.105-.747-.03zM12.5 4.787c-.151-.703-.377-1.315-.689-1.809-.312-.493-.728-.893-1.264-1.116-.539-.224-1.127-.24-1.73-.09l-.04.01-.04.016c-.404.151-.85.406-1.177.799a1.883 1.883 0 00-.41 1.627c.128.614.533 1.212 1.173 1.802a9.47 9.47 0 001.087.849H5a.75.75 0 00-.75.75v3.333c0 .415.336.75.75.75h.25v8.417c0 .414.336.75.75.75h13a.75.75 0 00.75-.75v-8.417H20a.75.75 0 00.75-.75V7.625a.75.75 0 00-.75-.75h-4.41c.43-.292.789-.575 1.087-.849.64-.59 1.045-1.188 1.173-1.802a1.883 1.883 0 00-.41-1.627c-.327-.393-.773-.648-1.177-.8l-.04-.014-.04-.01c-.603-.151-1.191-.135-1.73.09-.536.222-.952.622-1.264 1.115-.312.494-.538 1.106-.689 1.809zm-.8 5.421H5.75V8.375h5.95v1.833zm0 1.5H6.75v7.667h4.95v-7.667zm1.5 7.667v-7.667h5.05v7.667H13.2zm0-9.167V8.375h6.05v1.833H13.2zm3.087-6.651a1.307 1.307 0 00-.51-.34c-.33-.075-.568-.045-.747.03-.19.08-.387.239-.573.533-.319.503-.554 1.316-.654 2.442.85-.488 1.448-.923 1.857-1.3.522-.48.683-.818.721-1.004a.387.387 0 00-.094-.361z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgCharity = React.memo(SvgCharity);
export default MemoSvgCharity;
