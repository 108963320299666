import * as React from 'react';

const Lock = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1.5em"
      height="1.5em"
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        id="lock_icon"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="Group" transform="translate(4.000000, 1.000000)">
          <path
            d="M12.3529412,7.1 C13.0460606,7.1 13.6743275,7.3680995 14.1305204,7.79421373 C14.6097572,8.24185255 14.9,8.86132202 14.9,9.53846154 L14.9,9.53846154 L14.9,16.4615385 C14.9,17.1858138 14.6090945,17.7879259 14.1498013,18.216936 C13.6940171,18.6426685 13.0597423,18.9 12.3529412,18.9 L12.3529412,18.9 L1.64705882,18.9 C0.953939434,18.9 0.325672525,18.6319005 -0.130520363,18.2057863 C-0.609757215,17.7581474 -0.9,17.138678 -0.9,16.4615385 L-0.9,16.4615385 L-0.9,9.53846154 C-0.9,8.86132202 -0.609757215,8.24185255 -0.130520363,7.79421373 C0.325672525,7.3680995 0.953939434,7.1 1.64705882,7.1 L1.64705882,7.1 Z"
            id="Path"
            stroke="#363636"
            strokeWidth="1.8"
            fillRule="nonzero"
          ></path>
          <path
            d="M7,10 C8.1045695,10 9,10.8954305 9,12 C9,12.8198037 8.50675181,13.524403 7.80083694,13.8332166 L7.8,15.1 C7.8,15.5970563 7.39705627,16 6.9,16 C6.40294373,16 6,15.5970563 6,15.1 L5.99992752,13.7323937 C5.40216612,13.3865739 5,12.7402524 5,12 C5,10.8954305 5.8954305,10 7,10 Z"
            id="Combined-Shape"
            fill="#363636"
          ></path>
          <path
            d="M3,6 L3,4.29641894 C3,1.9455482 4.81132075,0 7,0 C9.18867925,0 11,1.9455482 11,4.29641894 L11,6"
            id="Path"
            stroke="#363636"
            strokeWidth="1.8"
            strokeLinecap="square"
            strokeLinejoin="bevel"
          ></path>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgLawyer = React.memo(Lock);
export default MemoSvgLawyer;
