import * as React from 'react';

const SvgStar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 34 32"
      {...props}
    >
      <path d="M35.2 11.2L22.4 9.371 16.914-2.286 11.2 9.371-1.371 11.2l9.143 8.914-2.057 12.8 11.2-5.943 11.2 5.943-2.057-12.8 9.143-8.914zM16.914 22.857l-6.629 3.429 1.143-7.314-5.257-5.257 7.543-1.143 3.2-6.629 3.2 6.629 7.314 1.143-5.257 5.257 1.143 7.314-6.4-3.429z" />
    </svg>
  );
};

const MemoSvgStar = React.memo(SvgStar);
export default MemoSvgStar;
