import * as React from 'react';

const SvgUser = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M15.999 6.264c-2.425 0-4.396 2.044-4.396 4.559 0 2.514 1.971 4.559 4.396 4.559s4.397-2.044 4.397-4.558c0-2.515-1.972-4.561-4.397-4.561zm0 11.403c-3.634 0-6.592-3.069-6.592-6.842C9.407 7.062 12.364 4 15.999 4c3.636 0 6.593 3.063 6.593 6.825 0 3.772-2.957 6.842-6.593 6.842zm11.985 8.971c.066.529-.082.841-.216 1.01-.17.214-.414.332-.727.349L26.97 28c-.746 0-1.093-.732-1.172-1.118-.401-3.101-2.959-5.423-5.954-5.423h-7.688c-2.995 0-5.554 2.322-5.95 5.401-.083.408-.431 1.14-1.177 1.14-.384-.019-.629-.138-.797-.351-.135-.17-.281-.481-.217-1.012.508-4.253 4.007-7.462 8.141-7.462h7.688c4.133 0 7.632 3.209 8.139 7.463z" />
    </svg>
  );
};

const MemoSvgUser = React.memo(SvgUser);
export default MemoSvgUser;
