/* istanbul ignore file */

import * as React from 'react';

const SvgTrash = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 18"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9 1.5A1.5 1.5 0 0110.5 3H15a.75.75 0 110 1.5h-.375v9.75a1.5 1.5 0 01-1.5 1.5h-8.25a1.5 1.5 0 01-1.5-1.5V4.5H3a.75.75 0 01-.743-.648L2.25 3.75A.75.75 0 013 3h4.5A1.5 1.5 0 019 1.5zm4.125 3h-8.25v9.75h8.25V4.5zM7.5 6.75c.414 0 .75.331.75.74v4.52c0 .409-.336.74-.75.74a.745.745 0 01-.75-.74V7.49c0-.409.336-.74.75-.74zm3 0c.414 0 .75.331.75.74v4.52c0 .409-.336.74-.75.74a.745.745 0 01-.75-.74V7.49c0-.409.336-.74.75-.74z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgTrash = React.memo(SvgTrash);
export default MemoSvgTrash;
