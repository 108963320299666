import * as React from 'react';

const SvgWineGlasses = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M298 175q-2 3-19 33-17 31-39 70t-41 75q-20 37-27 51-27 56-23 123.5t43 98.5l-74 159-71-33-15 31 174 81 14-31-71-33 75-159q4 1 8.5 1.5t8.5.5q47 0 94.5-39t71.5-90q7-15 22-53 16-39 31.5-80.5T489 306q12-33 13-36l6-15-202-94-8 14zm78 324q-23 48-71.5 84.5T222 604q-35-16-38-76.5T204 419q4-10 14.5-29.5T242 346h194q-17 45-35 91t-25 62zm88-227q-3 8-6.5 17.5T449 311H261q18-32 34-61t25-45l144 67zm442 512l-74-159q39-31 43-98.5T852 403q-7-14-26-51-20-36-41.5-75T745 207q-17-30-19-33l-8-14-202 94 6 15q1 3 13 36 13 33 29 74.5t31 80.5q15 38 22 52 24 52 72 91t94 39q4 0 8.5-.5t9.5-1.5l74 158-71 34 14 31 174-81-15-31-71 33zM560 272l144-67q12 21 35.5 62.5T784 348H589l-16.5-44-12.5-32zm243 331q-35 16-83.5-20.5T648 498q-5-12-18.5-45T602 382h200q6 12 11 21.5t7 14.5q23 48 20 108.5T803 603zM235 423q0 7 4.5 12t11.5 5 11.5-5 4.5-12q0-6-4.5-11t-11.5-5-11.5 5-4.5 11zm17 61q0 6 5 11t12 5 11.5-5 4.5-11q0-7-4.5-12t-11.5-5-12 5-5 12zm432-59q0-7-5-12t-11-5q-7 0-12 5t-5 12q0 6 5 11t12 5q6 0 11-5t5-11zm39 18q-7 0-11.5 4.5T707 459t4.5 12 11.5 5 12-5 5-12-5-11.5-12-4.5zm-34 44q-7 0-11.5 4.5T673 503t4.5 11.5T689 519t11.5-4.5T705 503t-4.5-11.5T689 487zm-472 5q-7 0-11.5 4.5T201 508t4.5 11.5T217 524t11.5-4.5T233 508q1-7-4-11.5t-12-4.5z" />
    </svg>
  );
};

const MemoSvgWineGlasses = React.memo(SvgWineGlasses);
export default MemoSvgWineGlasses;
