import * as React from 'react';

const SvgCosmetic = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M403 300H163q-15 0-25 9.5T128 333v595h309V333q0-14-10-23.5t-24-9.5zm-4 592H166V336h233v556zm461-204q0-1 .5-1.5l.5-.5V473q0-21-9-29t-31-8h-1V139q0-1-.5-1h-.5q0-6-.5-11t-3.5-10q-3-6-12-13.5T774 96q-29 0-65.5 20.5T648 167q-10 12-15.5 24t-6.5 23v222h-2q-22 0-31 8t-9 29v213l.5.5.5 1.5q-16 5-26 17.5T549 735v193h347V735q0-17-10-29.5T860 688zM678 188q21-25 49.5-40.5T774 132q4 0 6 .5l2 .5q0 2-1.5 10.5T767 167q-20 25-49 40.5T671 223q-4 0-5.5-.5t-2.5-.5q0-2 1.5-10.5T678 188zm-15 70q2 0 4 .5t4 .5q25 0 55.5-15.5T782 204v231H663V258zm-41 213h201v213H622V471zm236 421H587V735q0-6 4.5-10.5T603 720h240q6 0 10.5 4.5T858 735v157z" />
    </svg>
  );
};

const MemoSvgCosmetic = React.memo(SvgCosmetic);
export default MemoSvgCosmetic;
