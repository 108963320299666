import * as React from 'react';

const SvgKonesCar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 33 20"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero">
        <path d="M1.054 8.94a4.37 4.37 0 012.657-1.497l2.956-.462.008-.009 4.635-4.707A7.509 7.509 0 0116.241.014L19.721 0h3.628c2.772 0 5.229 1.788 6.113 4.449l.778 2.34h.197c1.413 0 2.562 1.164 2.562 2.595v4.472c0 1.431-1.149 2.595-2.562 2.595h-.975a3.79 3.79 0 01-.861 1.828h3.754c.356 0 .645.292.645.653a.649.649 0 01-.645.653H22.15a.649.649 0 01-.645-.653c0-.361.289-.653.645-.653h.81a3.79 3.79 0 01-.861-1.828h-11.2a3.79 3.79 0 01-.861 1.828h6.096c.356 0 .645.292.645.653a.649.649 0 01-.645.653H7.218c-1.841 0-3.375-1.356-3.681-3.134h-.782C1.236 16.451 0 15.199 0 13.66v-1.831c0-1.059.375-2.084 1.055-2.888l-.001-.001zm.503 1.602h1.116a.812.812 0 00.806-.816v-.894a3.08 3.08 0 00-1.922 1.71zm7.119-3.754h8.66V1.304h-.637a6.24 6.24 0 00-4.477 1.882L8.676 6.788zm9.95-5.25v5.25h5.183l-5.183-5.25zm9.614 3.326c-.707-2.129-2.673-3.56-4.891-3.56h-3.131l5.414 5.484h3.247l-.639-1.924zm2.197 3.23h-.016v1.632l.003.067a.66.66 0 01.052.227.807.807 0 00.751.522h.483V9.383c0-.711-.571-1.289-1.273-1.289zm-2.208 7.703c0-1.368-1.099-2.481-2.449-2.481-1.35 0-2.449 1.113-2.449 2.481 0 1.368 1.099 2.481 2.449 2.481 1.35 0 2.449-1.113 2.449-2.481zM7.217 18.278c1.35 0 2.449-1.113 2.449-2.481 0-1.368-1.099-2.481-2.449-2.481-1.35 0-2.449 1.113-2.449 2.481 0 1.368 1.099 2.481 2.449 2.481zm-4.463-3.133h.782c.306-1.778 1.84-3.134 3.681-3.134s3.375 1.356 3.681 3.134h11.2c.306-1.778 1.84-3.134 3.681-3.134s3.375 1.356 3.681 3.134h.975c.702 0 1.273-.578 1.273-1.289v-2.008h-.483a2.092 2.092 0 01-1.857-1.143H27.6a.649.649 0 01-.645-.653c0-.361.289-.653.645-.653h1.531V8.093H7.997l-3.229.505v1.127c0 1.17-.94 2.122-2.095 2.122H1.287v1.812c0 .819.658 1.485 1.466 1.485l.001.001z" />
        <path d="M18.668 9.388h-2.561a.66.66 0 100 1.321h2.561a.661.661 0 000-1.322v.001zm-.096 8.89a.649.649 0 01.645.653.647.647 0 01-.645.653.647.647 0 01-.645-.653.647.647 0 01.645-.653zM25.136 9.4a.649.649 0 01.645.653c0 .172-.069.34-.189.462a.643.643 0 01-1.101-.462c0-.172.069-.34.189-.462a.643.643 0 01.456-.191z" />
      </g>
    </svg>
  );
};

const MemoSvgKonesCar = React.memo(SvgKonesCar);
export default MemoSvgKonesCar;
