import * as React from 'react';

const SvgQuestionMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8 .5a7.5 7.5 0 110 15 7.5 7.5 0 010-15zM8 2a6 6 0 100 12A6 6 0 008 2zm0 9a.75.75 0 110 1.5.75.75 0 010-1.5zM5.001 6.646c.205-3.362 5.25-3.363 5.488-.186.094 1.248-.328 1.993-1.192 2.605-.051.036-.172.118-.251.171l-.075.051c-.22.152-.244.183-.245.216a.75.75 0 11-1.5-.035c.015-.639.316-1.016.892-1.415.03-.02.252-.17.313-.213.463-.328.613-.593.563-1.268-.1-1.322-2.405-1.322-2.495.165A.75.75 0 115 6.646z"
        fill="#000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgQuestionMark = React.memo(SvgQuestionMark);
export default MemoSvgQuestionMark;
