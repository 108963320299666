import * as React from 'react';

const SvgMail = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 20 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.5.25l.146.006a1.695 1.695 0 011.548 1.688v9.723l-.006.146A1.695 1.695 0 0117.5 13.36H1.944l-.146-.006A1.695 1.695 0 01.25 11.667V1.912l.006-.108.017-.143C.405.86 1.107.25 1.944.25H17.5zm.25 2.55l-6.686 5.246-.164.118a2.667 2.667 0 01-2.953.008l-.155-.11-6.098-4.658v8.513H17.75V2.799zm-.931-1.106H1.835l6.84 5.225L8.8 7c.379.223.853.226 1.237.001l.123-.083 6.66-5.225z"
        fill="#000000"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgMail = React.memo(SvgMail);
export default MemoSvgMail;
