import * as React from 'react';

const SvgEducation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.908 5.183l8.501 3.86a.999.999 0 01-.017 1.83l-3.313 1.434v3.583c0 .735-.406 1.412-1.06 1.763C15.346 18.551 13.673 19 12 19c-1.673 0-3.346-.449-5.019-1.347a2.003 2.003 0 01-1.06-1.763l-.001-3.556-3.315-1.451a.999.999 0 01-.009-1.831l8.63-3.873a2.046 2.046 0 011.683.004zm3.65 7.782l-3.807 1.65a2.046 2.046 0 01-1.632-.004L7.439 13v2.89a.5.5 0 00.19.391l.076.05c1.457.783 2.883 1.166 4.295 1.166 1.41 0 2.837-.383 4.295-1.165a.501.501 0 00.256-.35l.009-.091-.001-2.925zM11.937 6.52l-.083.03-7.6 3.41 2.326 1.017.204.06.277.088.305.106.499.184.906.357 2.541 1.046.478.191c.074.029.144.056.21.08l.616-.238 1.293-.528 1.887-.782.38-.152.357-.137.33-.12.3-.102.136-.043.103-.029 2.346-1.015-7.473-3.393a.511.511 0 00-.338-.03z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgEducation = React.memo(SvgEducation);
export default MemoSvgEducation;
