import * as React from 'react';

const SvgTender = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFAB00"
          d="M5 27.667l9.914.333 39.074.451L54 17H13.554l-6.376 2.426L5 23.978z"
        />
        <g transform="translate(5 10)">
          <ellipse fill="#FFF" cx={8.49} cy={16.471} rx={3.671} ry={3.529} />
          <ellipse fill="#FFF" cx={38.611} cy={16.471} rx={3.671} ry={3.529} />
          <path
            d="M27.108 14.118h9.043m5.274 0H50"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
          />
          <path
            d="M4.878 16.84l-3.487-.264a1.2 1.2 0 01-1.079-1.465l.99-4.306a4.8 4.8 0 013.66-3.617l4.251-.92h0l39.342.41a1.2 1.2 0 011.188 1.2v8.142a1.2 1.2 0 01-1.2 1.2h-2.959 0m-10.823-.127l-19.442.127"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
          />
          <path
            d="M27.063 16.47V1.2a1.2 1.2 0 00-1.2-1.2h-6.501a4.8 4.8 0 00-2.831.924L9.213 6.267h0"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            d="M18.478.706L16.443 10.91l1.353 5.56"
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            d="M44.512 6.802l1.398 2.673h3.82"
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={8.49}
            cy={16.471}
            rx={3.671}
            ry={3.529}
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={38.611}
            cy={16.471}
            rx={3.671}
            ry={3.529}
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.687 10h2.409"
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgTender = React.memo(SvgTender);
export default MemoSvgTender;
