import * as React from 'react';

const SvgShiled = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.43 2.393L3.507 5.036A1.75 1.75 0 002.322 6.9c.228 1.93.951 4.361 2.168 7.306 1.35 3.27 3.526 5.939 6.517 7.996.584.4 1.352.41 1.945.024 3.048-1.99 5.23-4.647 6.533-7.956 1.16-2.947 1.893-5.379 2.2-7.306a1.75 1.75 0 00-1.175-1.938l-7.977-2.634a1.75 1.75 0 00-1.102.001zm.633 1.423l7.975 2.633a.25.25 0 01.166.278c-.287 1.805-.992 4.14-2.115 6.994-1.188 3.017-3.168 5.428-5.956 7.248a.25.25 0 01-.277-.004c-2.752-1.893-4.742-4.332-5.98-7.331-1.168-2.83-1.855-5.137-2.065-6.91a.25.25 0 01.17-.265l7.924-2.643a.25.25 0 01.158 0zm5.247 4.427a.75.75 0 00-1.06.044l-4.72 5.131-.03.029a.25.25 0 01-.35-.044l-1.776-2.278-.074-.083a.75.75 0 00-1.109 1.004l1.775 2.279.103.121a1.75 1.75 0 002.565-.012l4.72-5.131.07-.087a.75.75 0 00-.114-.973z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgShiled = React.memo(SvgShiled);
export default MemoSvgShiled;
