import * as React from 'react';

const SvgStorage = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M31.555 10.479L16.307 2.766a.733.733 0 00-.664 0L.395 10.479a.776.776 0 00-.42.694V29.18c0 .819.648 1.485 1.444 1.485h29.112c.796 0 1.444-.666 1.444-1.485V11.173a.776.776 0 00-.42-.694zm-6.39 10.267H6.785v-2.657h18.38v2.657zm-18.38 1.547h18.38v2.657H6.785v-2.657zm18.38-5.751H6.785v-2.758h18.38v2.758zm-18.38 9.954h18.38v2.621H6.785v-2.621zm23.685 2.622h-3.801V13.011a.763.763 0 00-.752-.773H6.033a.763.763 0 00-.752.773v16.107H1.48V11.655l14.496-7.333 14.496 7.333v17.463z" />
    </svg>
  );
};

const MemoSvgStorage = React.memo(SvgStorage);
export default MemoSvgStorage;
