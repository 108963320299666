import * as React from 'react';

const SvgHistory = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M17.207 28c-.618 0-1.122-.501-1.122-1.115s.504-1.113 1.122-1.113c5.45 0 9.885-4.383 9.885-9.77 0-5.39-4.435-9.773-9.885-9.773s-9.886 4.383-9.886 9.773c-.001.066-.032 1.221.228 2.521l.425-1.041a1.112 1.112 0 011.038-.692c.142 0 .282.026.416.078.28.115.499.328.615.605.115.272.117.573.004.848L8.56 21.959 8.551 22l-.069.112-.131.172a1.007 1.007 0 01-.105.1 1.558 1.558 0 01-.241.156l-.137.055a1.554 1.554 0 01-.168.037l-.153.016-.159-.007-.134-.331-.091.28-.172-.072-3.766-2.166a1.108 1.108 0 01-.404-1.526c.203-.34.572-.551.968-.551.196 0 .39.053.559.15l1.012.583a13.85 13.85 0 01-.279-3.055C5.081 9.368 10.521 4 17.206 4s12.127 5.383 12.128 12.001C29.334 22.617 23.893 28 17.207 28zm-.052-8.811l-.077-.024a.794.794 0 01-.315-.08l-4.807-2.362c-.558-.274-.819-.993-.591-1.635.174-.482.583-.791 1.04-.791.155 0 .305.034.448.105l4.086 2.007 4.629-5.433c.213-.249.499-.387.808-.387s.594.138.808.389a1.384 1.384 0 01-.001 1.757l-5.17 6.065c-.13.125-.216.191-.311.244-.238.115-.368.144-.497.144h-.048z" />
    </svg>
  );
};

const MemoSvgHistory = React.memo(SvgHistory);
export default MemoSvgHistory;
