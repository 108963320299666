import * as React from 'react';

const SvgDropdown = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 11 6"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.793.232a.5.5 0 10-.731.682L5.613 5.79a.5.5 0 00.733-.003l4.47-4.853a.5.5 0 00-.736-.677L5.976 4.712 1.793.232z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgDropdown = React.memo(SvgDropdown);
export default MemoSvgDropdown;
