import * as React from 'react';

const SvgMisharihover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M5 30h.471l47.185-.02.344-6.565-1.972-2.736-.426-10.731a3 3 0 00-3.067-2.88l-21.783.499-.537 13.489H14.46l-8.772 2.359"
          fill="#FFAB00"
        />
        <g transform="translate(5 7)">
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.353 16.792h2.161"
          />
          <path
            d="M44.177 13.812v2.17c0 .597.483 1.08 1.08 1.08h2.16M0 18.146l4.42-.88c.505-.1.87-.544.87-1.059v-1.311h0"
            stroke="#000"
            strokeWidth={1.2}
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M47.759 21.125h2.471"
          />
          <ellipse fill="#FFF" cx={9.241} cy={22.208} rx={3.84} ry={3.792} />
          <path
            d="M45.257 13.812V2.16A2.16 2.16 0 0043.097 0H22.274a2.16 2.16 0 00-2.16 2.16v11.365h0v8.628"
            stroke="#000"
            strokeWidth={1.2}
          />
          <path
            d="M20.114 5.06h-6.217a3.24 3.24 0 00-3 2.02l-1.355 3.325c-.054.13-.08.27-.08.41v2.727h35.795l1.041.686a3.252 3.252 0 011.461 2.716v4.742c0 .598-.483 1.083-1.08 1.083h-1.855 0m-11.16 0h-17.23m-11.458 0H1.08c-.596 0-1.08-.485-1.08-1.083v-3.458c0-1.483 1-2.778 2.433-3.148l7.028-1.815h0"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={9.412}
            cy={22.208}
            rx={3.781}
            ry={3.792}
          />
          <ellipse fill="#FFF" cx={40.567} cy={22.208} rx={3.84} ry={3.792} />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={40.738}
            cy={22.208}
            rx={3.781}
            ry={3.792}
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgMisharihover = React.memo(SvgMisharihover);
export default MemoSvgMisharihover;
