import * as React from 'react';

const SvgCloseBold = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M3.057 3.057a1.333 1.333 0 011.886 0L16 14.113 27.057 3.057a1.333 1.333 0 011.76-.111l.126.111a1.333 1.333 0 010 1.886L17.887 16l11.056 11.057c.481.481.518 1.237.111 1.76l-.111.126a1.333 1.333 0 01-1.886 0L16 17.887 4.943 28.943a1.333 1.333 0 01-1.76.111l-.126-.111a1.333 1.333 0 010-1.886L14.113 16 3.057 4.943a1.333 1.333 0 01-.111-1.76l.111-.126z" />
    </svg>
  );
};

const MemoSvgCloseBold = React.memo(SvgCloseBold);
export default MemoSvgCloseBold;
