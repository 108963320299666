import * as React from 'react';

const SvgCheckMark = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5.499 12.472l3.583 4.09a1.333 1.333 0 001.99.018l7.384-8.132a.667.667 0 00-.988-.896l-7.383 8.132-3.584-4.091a.667.667 0 00-1.002.879z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgCheckMark = React.memo(SvgCheckMark);
export default MemoSvgCheckMark;
