import * as React from 'react';

const SvgFacebookIcon = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 19 32"
      {...props}
    >
      <path d="M18.477.007L13.872 0C8.698 0 5.355 3.091 5.355 7.875v3.631H.725c-.4 0-.724.292-.724.653v5.261c0 .361.324.652.724.652h4.63v13.275c0 .361.324.653.724.653h6.041c.4 0 .724-.292.724-.652V18.073h5.414c.4 0 .724-.292.724-.652l.002-5.261c0-.173-.076-.339-.212-.461s-.32-.191-.512-.191h-5.416V8.43c0-1.479.391-2.23 2.53-2.23l3.102-.001c.4 0 .724-.292.724-.652V.662c0-.36-.324-.652-.723-.652z" />
    </svg>
  );
};

const MemoSvgFacebookIcon = React.memo(SvgFacebookIcon);
export default MemoSvgFacebookIcon;
