import * as React from 'react';

const SvgHorsePower = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 18 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M3.432 23.938l5.792-9.955v-.202A.788.788 0 008.428 13H1.841L8.204 2.062h7.955l-5.485 8.082s-.183.323-.183.512c0 .432.356.781.796.781h4.872l-12.727 12.5z"
          fill="#FFF"
        />
        <path
          d="M16.159 11.438L9.263 18.21l2.46-4.227v-.202a.788.788 0 00-.795-.781H4.341l6.363-10.938h5.455l-5.485 8.082s-.183.323-.183.512c0 .432.356.781.796.781h4.872z"
          fill="#8DCED6"
        />
        <path
          d="M16.159 9.875h-3.39l4.714-6.946c.325-.479.355-1.095.079-1.604A1.596 1.596 0 0016.159.5H8.204c-.57 0-1.098.3-1.38.787L.46 12.225a1.538 1.538 0 00.005 1.56c.284.481.809.777 1.376.777h5.213l-5.003 8.6a1.545 1.545 0 00.415 2.017c.287.216.627.321.965.321a1.6 1.6 0 001.126-.458l12.727-12.5a1.542 1.542 0 00.345-1.703 1.593 1.593 0 00-1.47-.964zM3.432 23.938l5.792-9.955v-.202A.788.788 0 008.428 13H1.841L8.204 2.062h7.955l-5.485 8.082s-.183.323-.183.512c0 .432.356.781.796.781h4.872l-12.727 12.5z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgHorsePower = React.memo(SvgHorsePower);
export default MemoSvgHorsePower;
