import * as React from 'react';

const SvgEngineType = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M14.813 2.349H5.438c-.934 0-1.688.793-1.688 1.77v19.407l.125.125h12.5l.125-.125V4.118c0-.976-.754-1.77-1.687-1.77z"
          fill="#FFF"
        />
        <path
          d="M16.5 4.973v18.553l-.125.125h-10V6.617c0-.908.7-1.644 1.563-1.644H16.5z"
          fill="#8DCED6"
        />
        <path
          d="M6.688 4.572h6.875c.447 0 .812.385.812.862v5.921c0 .478-.365.862-.812.862H6.688c-.448 0-.813-.384-.813-.862v-5.92c0-.478.365-.863.813-.863z"
          fill="#363636"
        />
        <path
          fill="#FFF"
          d="M12.625 6.296h-5l-.125.125v3.947l.125.125h5l.125-.125V6.421z"
        />
        <path
          d="M14.813.625c1.828 0 3.312 1.563 3.312 3.493v11.354c.009.065.012.112.012.16v2.309c0 .972.752 1.763 1.682 1.763.93 0 1.681-.79 1.681-1.763v-7.682c0-.382-.118-.754-.336-1.06L19.79 7.27a.896.896 0 01.163-1.21.782.782 0 011.134.171l1.375 1.93a3.62 3.62 0 01.664 2.099v7.682c0 1.927-1.482 3.487-3.306 3.487a3.155 3.155 0 01-1.51-.384l-.184.11v2.372l.125.125h.313c.447 0 .812.385.812.862 0 .478-.365.862-.812.862H1.688c-.448 0-.813-.384-.813-.862 0-.477.365-.862.813-.862H2l.125-.125V4.118c0-1.93 1.485-3.493 3.313-3.493h9.375zm0 1.724H5.438c-.934 0-1.688.793-1.688 1.77v19.407l.125.125h12.5l.125-.125V4.118c0-.976-.754-1.77-1.687-1.77z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgEngineType = React.memo(SvgEngineType);
export default MemoSvgEngineType;
