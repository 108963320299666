import * as React from 'react';

const SvgJeep = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M10.782 30l34.526-.406 1.93-3.722H50v-8.115c0-.59-.92-.775-2.763-.554l-.891-4.662L42.95 12l-.136 8.357H18.592L10 22.873 10.782 30z"
          fill="#FFAB00"
        />
        <g transform="translate(10 8)">
          <path
            d="M24.426 21.23h-7.651m-12.368 0H1.198A1.194 1.194 0 010 20.04v-4.748c0-1.148.825-2.133 1.962-2.341l5.844-1.07 3-5.344a4.794 4.794 0 014.411-2.9l18.732-.066a2.389 2.389 0 012.397 2.381V20.04c0 .657-.537 1.19-1.198 1.19"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <ellipse fill="#FFF" cx={9.052} cy={20.833} rx={4.259} ry={4.167} />
          <ellipse fill="#FFF" cx={28.223} cy={20.833} rx={4.259} ry={4.167} />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="square"
            d="M8.592 11.806h26.716M20.772 3.638v16.336M19.574.926V2.93M31.556.926V2.93"
          />
          <path
            stroke="#000"
            strokeWidth={1.2}
            strokeLinecap="round"
            d="M17.397.595h15.961"
          />
          <path
            d="M36.346 8.4H38.8A1.2 1.2 0 0140 9.6v6.396a1.2 1.2 0 01-1.2 1.2h-2.454 0V8.399z"
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={9.131}
            cy={20.37}
            rx={4.194}
            ry={4.167}
          />
          <ellipse
            stroke="#000"
            strokeWidth={1.2}
            strokeLinejoin="round"
            cx={28.62}
            cy={20.37}
            rx={4.194}
            ry={4.167}
          />
        </g>
        <path
          stroke="#000"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M25.576 22.881h2.397"
        />
        <path
          stroke="#000"
          strokeWidth={1.2}
          strokeLinecap="square"
          d="M42.95 19.31v-7.143"
        />
        <path
          stroke="#000"
          strokeWidth={1.2}
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10.782 23.476l4.258-.94-1.06-1.705"
        />
      </g>
    </svg>
  );
};

const MemoSvgJeep = React.memo(SvgJeep);
export default MemoSvgJeep;
