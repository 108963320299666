import * as React from 'react';

const SvgWallet1 = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M30.216 17.126V12.91a2.72 2.72 0 00-2.51-2.705L23.79 3.366a2.69 2.69 0 00-1.65-1.272 2.683 2.683 0 00-2.052.275L6.665 10.184H4.307a2.73 2.73 0 00-2.727 2.727v16.363a2.73 2.73 0 002.727 2.727h23.181a2.73 2.73 0 002.727-2.727v-4.216a2.042 2.042 0 001.364-1.92v-4.091c0-.888-.572-1.637-1.364-1.92zm-4.095-6.944h-5.943l4.457-2.595 1.486 2.595zm-2.163-3.778l-6.49 3.778h-2.692l8.508-4.954.673 1.176zm-3.184-2.858a1.335 1.335 0 011.832.497l.001.002-10.541 6.137H9.374l11.399-6.636zm8.078 25.727c0 .752-.612 1.364-1.364 1.364H4.307a1.366 1.366 0 01-1.364-1.364V12.91c0-.752.612-1.364 1.364-1.364h23.181c.752 0 1.364.612 1.364 1.364v4.091h-4.091c-2.256 0-4.091 1.835-4.091 4.091s1.835 4.091 4.091 4.091h4.091v4.091zm1.364-6.136a.683.683 0 01-.682.682h-4.773c-1.504 0-2.727-1.223-2.727-2.727s1.223-2.727 2.727-2.727h4.773c.376 0 .682.306.682.682v4.091z" />
      <path d="M24.761 19.727c-.752 0-1.364.612-1.364 1.364s.612 1.364 1.364 1.364c.752 0 1.364-.612 1.364-1.364s-.612-1.364-1.364-1.364z" />
    </svg>
  );
};

const MemoSvgWallet1 = React.memo(SvgWallet1);
export default MemoSvgWallet1;
