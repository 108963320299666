import * as React from 'react';

const SvgBook = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M958 739q-27-20-79.5-41T743 677q-85 0-136.5 23T529 744V338q1 0 1-.5t1-1.5q8-10 56.5-43.5T742 259q61 0 103 12t68 27q13 7 22.5 14t14.5 12q3 2 4.5 3.5t2.5 3.5v408h1zm-676-62q-83 0-136 21t-80 41V331q12-12 62.5-42T282 259q60 0 101 13t67 28q12 8 21 15t14 13l8 8 1 1.5 1 .5v406q-26-21-77.5-44T282 677zm706-363q-1-3-58-46.5T743 224q-101 0-156.5 30.5T512 305q-19-20-74.5-50.5T282 224q-131 0-188 43.5T36 314q-2 2-3 4.5t-1 5.5v454q0 5 3 9.5t9 6.5q5 2 10 0t9-6l2-2q9-10 59.5-42T282 712q60 0 101 13t66 28q13 8 21.5 15.5T485 781l5 5 3 4 2 2q2 4 6 6t8 2h6q5 0 8.5-2t5.5-6l2-2q9-11 57-44.5T742 712q61 0 103 12.5t68 26.5q13 8 22.5 14.5T950 778q3 2 5.5 4t3.5 4l2 2q3 5 8.5 6.5t10.5-.5 8.5-6.5 3.5-9.5V324q0-3-1-5.5t-3-4.5z" />
    </svg>
  );
};

const MemoSvgBook = React.memo(SvgBook);
export default MemoSvgBook;
