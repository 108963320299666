import * as React from 'react';

const SvgGearbox = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 30 29"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <defs>
        <path
          d="M4.063 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          id="gearbox_svg__a"
        />
        <path
          d="M4.063 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          id="gearbox_svg__c"
        />
        <path
          d="M4.063 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          id="gearbox_svg__e"
        />
        <path
          d="M4.063 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          id="gearbox_svg__g"
        />
        <path
          d="M4.063 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
          id="gearbox_svg__i"
        />
      </defs>
      <g fill="none" fillRule="evenodd">
        <path
          d="M25 8.073v5.239c0 .686-.556 1.241-1.241 1.241H5h0"
          stroke="#363636"
          strokeWidth={1.738}
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fill="#363636"
          d="M4.125 7.5h1.75v15h-1.75zM14.125 7.5h1.75v15h-1.75z"
        />
        <g transform="translate(.938 .938)">
          <path
            d="M4.063 0a4.062 4.062 0 100 8.125 4.062 4.062 0 000-8.125z"
            fill="#363636"
            fillRule="nonzero"
          />
          <mask id="gearbox_svg__b" fill="#fff">
            <use xlinkHref="#gearbox_svg__a" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#gearbox_svg__a" />
          <path
            d="M5.988 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
            fill="#8DCED6"
            fillRule="nonzero"
            mask="url(#gearbox_svg__b)"
          />
        </g>
        <g transform="translate(.938 20.625)">
          <path
            d="M4.063 0a4.062 4.062 0 100 8.125 4.062 4.062 0 000-8.125z"
            fill="#363636"
            fillRule="nonzero"
          />
          <mask id="gearbox_svg__d" fill="#fff">
            <use xlinkHref="#gearbox_svg__c" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#gearbox_svg__c" />
          <path
            d="M5.988 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
            fill="#8DCED6"
            fillRule="nonzero"
            mask="url(#gearbox_svg__d)"
          />
        </g>
        <g transform="translate(10.938 .938)">
          <path
            d="M4.063 0a4.062 4.062 0 100 8.125 4.062 4.062 0 000-8.125z"
            fill="#363636"
            fillRule="nonzero"
          />
          <mask id="gearbox_svg__f" fill="#fff">
            <use xlinkHref="#gearbox_svg__e" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#gearbox_svg__e" />
          <path
            d="M5.988 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
            fill="#8DCED6"
            fillRule="nonzero"
            mask="url(#gearbox_svg__f)"
          />
        </g>
        <g transform="translate(10.938 20.625)">
          <path
            d="M4.063 0a4.062 4.062 0 100 8.125 4.062 4.062 0 000-8.125z"
            fill="#363636"
            fillRule="nonzero"
          />
          <mask id="gearbox_svg__h" fill="#fff">
            <use xlinkHref="#gearbox_svg__g" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#gearbox_svg__g" />
          <path
            d="M5.988 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
            fill="#8DCED6"
            fillRule="nonzero"
            mask="url(#gearbox_svg__h)"
          />
        </g>
        <g transform="translate(20.938 .938)">
          <path
            d="M4.063 0a4.062 4.062 0 100 8.125 4.062 4.062 0 000-8.125z"
            fill="#363636"
            fillRule="nonzero"
          />
          <mask id="gearbox_svg__j" fill="#fff">
            <use xlinkHref="#gearbox_svg__i" />
          </mask>
          <use fill="#FFF" fillRule="nonzero" xlinkHref="#gearbox_svg__i" />
          <path
            d="M5.988 1.563a2.5 2.5 0 100 5 2.5 2.5 0 000-5z"
            fill="#8DCED6"
            fillRule="nonzero"
            mask="url(#gearbox_svg__j)"
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgGearbox = React.memo(SvgGearbox);
export default MemoSvgGearbox;
