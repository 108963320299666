import * as React from 'react';

const SvgYoutube = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 46 32"
      {...props}
    >
      <path
        fill="red"
        d="M44.762 5.007A5.726 5.726 0 0040.734.979C37.158 0 22.852 0 22.852 0S8.546 0 4.97.941C3.05 1.468 1.469 3.049.942 5.007.001 8.583.001 16 .001 16s0 7.454.941 10.993a5.726 5.726 0 004.028 4.028C8.584 32 22.852 32 22.852 32s14.306 0 17.882-.941a5.726 5.726 0 004.028-4.028c.941-3.576.941-10.993.941-10.993s.038-7.454-.941-11.031z"
      />
      <path fill="#fff" d="M30.193 16L18.297 9.148v13.704z" />
    </svg>
  );
};

const MemoSvgYoutube = React.memo(SvgYoutube);
export default MemoSvgYoutube;
