import * as React from 'react';

const SvgNotificationError = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#CF1A1B" cx={12} cy={12} r={12} />
        <path
          d="M15.317 8.117a.4.4 0 11.566.566L12.565 12l3.318 3.317a.4.4 0 01.051.503l-.051.063a.4.4 0 01-.566 0L12 12.565l-3.317 3.318a.4.4 0 01-.503.051l-.063-.051a.4.4 0 010-.566L11.435 12 8.117 8.683a.4.4 0 01-.051-.503l.051-.063a.4.4 0 01.566 0L12 11.435z"
          fill="#FFE8E8"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

const MemoSvgNotificationError = React.memo(SvgNotificationError);
export default MemoSvgNotificationError;
