import * as React from 'react';

const SvgFood = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M32 832h960v-39H32v39zm89-121q4-79 36-148 31-69 84-120t123-81q69-30 148-30t148 30q70 29 123 80.5T867 563q32 69 36 148H121zm820 0q-3-84-36-159-33-74-88.5-130T688 332t-157-38v-63h38v-39H455v39h38v63q-84 4-157 38t-128.5 90T119 552q-33 75-36 159H32v39h960v-39h-51z" />
    </svg>
  );
};

const MemoSvgFood = React.memo(SvgFood);
export default MemoSvgFood;
