import * as React from 'react';

const SvgTableSort = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fillRule="nonzero" fill="none">
        <path
          d="M9.243 13.885a.584.584 0 01.719.03l.053.052 2.44 2.68 2.462-2.658.05-.047a.583.583 0 01.852.784l-.046.056-2.586 2.79a1 1 0 01-1.473-.006l-2.562-2.814-.043-.053a.584.584 0 01.03-.718l.052-.053.052-.043z"
          fill="#FF7100"
        />
        <path
          d="M13.187 6.798l2.586 2.79a.583.583 0 01-.806.84l-.05-.047-2.462-2.657-2.44 2.68a.583.583 0 01-.772.08l-.052-.042a.583.583 0 01-.082-.771l.043-.053 2.562-2.813a1 1 0 011.473-.007z"
          fill="#363636"
        />
      </g>
    </svg>
  );
};

const MemoSvgTableSort = React.memo(SvgTableSort);
export default MemoSvgTableSort;
