import * as React from 'react';

const SvgNewTab = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M23.867 25.2v-7.605c0-.589.478-1.067 1.067-1.067h.4c.589 0 1.067.478 1.067 1.067v9.072c0 .589-.478 1.067-1.067 1.067H5.067A1.067 1.067 0 014 26.667V6.4c0-.589.478-1.067 1.067-1.067h9.102c.589 0 1.067.478 1.067 1.067v.4c0 .589-.478 1.067-1.067 1.067H6.534V25.2h17.333z" />
      <path d="M24.11 10.792l-7.356 7.356a1.065 1.065 0 01-1.508 0l-1.158-1.158a1.065 1.065 0 010-1.508l7.371-7.371-2.941-2.975A.668.668 0 0118.992 4h8.418c.368 0 .667.298.667.667v8.514a.667.667 0 01-1.141.469l-2.825-2.857z" />
    </svg>
  );
};

const MemoSvgNewTab = React.memo(SvgNewTab);
export default MemoSvgNewTab;
