import * as React from 'react';

const SvgSecondHand = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.24 7.599C4.24 6.175 5.293 5 6.64 5h10.72c1.347 0 2.4 1.198 2.4 2.599v1.108h.46c.826 0 1.48.739 1.48 1.596v5.168c0 .857-.654 1.596-1.48 1.596h-.54v1.26c0 .574-.434 1.073-1 1.073h-2.02c-.567 0-1-.499-1-1.072v-1.261H8.34v1.26c0 .574-.434 1.073-1 1.073H5.32c-.567 0-1-.499-1-1.072v-1.261h-.54c-.827 0-1.48-.739-1.48-1.596v-5.168c0-.857.653-1.596 1.48-1.596h.46V7.6zm2.4-1.065c-.514 0-.96.46-.96 1.065v1.115c.759.078 1.34.78 1.34 1.589v1.588h4.48V6.534H6.64zm6.36 0v5.357h4v-1.588c0-.808.58-1.511 1.34-1.589V7.6c0-.604-.447-1.065-.96-1.065H13zm-9.242 3.72a.08.08 0 00-.018.049v5.168c0 .022.008.038.018.049.012.012.022.013.022.013h16.44c.01 0 .033-.006.056-.028.021-.02.023-.035.024-.035v-5.167a.068.068 0 00-.019-.049c-.011-.012-.02-.013-.021-.013h-1.78c-.004 0-.011.001-.021.013a.08.08 0 00-.02.049v2.355c0 .4-.313.767-.72.767H6.32c-.406 0-.72-.368-.72-.767v-2.355a.068.068 0 00-.018-.049c-.011-.012-.02-.013-.021-.013H3.78c-.004 0-.011.001-.022.013zM6.9 17.067H5.78v.777H6.9v-.777zm10.22 0v.777h1.12v-.777h-1.12z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgSecondHand = React.memo(SvgSecondHand);
export default MemoSvgSecondHand;
