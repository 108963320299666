import { environment } from '../../environments/environment';
import env from '@y2/environments';

type FileName = string;

export const getAssetPath = (fileName: FileName) =>
  `${environment.routes.assets}/pricelist/${fileName}`;

export const imagesHost = `${env.assets.basePath}/`;

export const modelImgBasePath = imagesHost;

export const manufacturerImgBasePath = imagesHost;
