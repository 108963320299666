import * as React from 'react';

const SvgProfessions = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.853 11.937a6.601 6.601 0 005.76 3.388 6.554 6.554 0 001.661-.218l.016.02 4.835 5.928a2.567 2.567 0 003.624.356l.312-.26a2.61 2.61 0 00.353-3.653L15.58 11.57a1.518 1.518 0 00-.023-.027 6.703 6.703 0 00-1.15-7.45 6.574 6.574 0 00-7.058-1.696.87.87 0 00-.366 1.361l2.902 3.553L8.245 8.67 5.35 5.117a.855.855 0 00-1.396.1 6.709 6.709 0 00-.1 6.72zm3.49 1.096a4.953 4.953 0 01-2.446-5.737l2.565 3.14a.857.857 0 001.212.12L11.635 8.1a.867.867 0 00.119-1.22L9.189 3.74a4.865 4.865 0 014.207 1.784 4.969 4.969 0 01.014 6.244 4.873 4.873 0 01-6.067 1.266zm10.106 6.921l-4.457-5.465 1.631-1.36 4.464 5.466a.87.87 0 01-.117 1.219l-.312.259a.854.854 0 01-1.209-.119z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgProfessions = React.memo(SvgProfessions);
export default MemoSvgProfessions;
