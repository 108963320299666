import * as React from 'react';

const SvgLocation = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M12.005 2C16.325 2 20 5.618 20 9.698c0 3.716-3.664 9.664-7.313 12.13a1 1 0 01-1.143-.016C7.902 19.197 4 13.265 4 9.698 4 5.62 7.684 2 12.005 2zm0 2C8.78 4 6 6.732 6 9.698c0 2.66 3.134 7.583 6.141 10.051C15.091 17.41 18 12.514 18 9.7 18 6.728 15.227 4 12.005 4zM12 7a3 3 0 110 6 3 3 0 010-6zm0 2a1 1 0 100 2 1 1 0 000-2z"
        fill="#363636"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgLocation = React.memo(SvgLocation);
export default MemoSvgLocation;
