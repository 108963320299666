import * as React from 'react';

const SvgSearch = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.125 6.167a4.958 4.958 0 013.84 8.094l2.548 2.547a.583.583 0 01-.825.825l-2.561-2.561a4.958 4.958 0 11-3.002-8.905zm0 1.166a3.792 3.792 0 100 7.584 3.792 3.792 0 000-7.584z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgSearch = React.memo(SvgSearch);
export default MemoSvgSearch;
