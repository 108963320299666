import * as React from 'react';

const SvgShiningArrow = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M31.685 27.065l-6.368-6.368 4.543-2.624a1.07 1.07 0 00-.139-1.921L9.462 8.071a1.07 1.07 0 00-1.39 1.39l8.08 20.262a1.07 1.07 0 001.921.139l2.624-4.543 6.368 6.368a1.07 1.07 0 001.514 0l3.108-3.108a1.07 1.07 0 000-1.513zm-3.864 2.351l-6.597-6.598a1.071 1.071 0 00-1.683.221l-2.218 3.84-6.341-15.899 15.898 6.341-3.841 2.218a1.068 1.068 0 00-.222 1.683l6.597 6.598-1.594 1.594zM4.699 3.184a1.07 1.07 0 00-1.514 1.514l2.378 2.378a1.07 1.07 0 001.514-1.514L4.699 3.184zm.806 7.398a1.07 1.07 0 00-1.07-1.07H1.072a1.07 1.07 0 100 2.14h3.363a1.07 1.07 0 001.07-1.07zm-.434 3.303l-2.378 2.378a1.07 1.07 0 101.513 1.513l2.378-2.378a1.07 1.07 0 10-1.513-1.513zm5.512-8.382a1.07 1.07 0 001.07-1.07V1.07a1.07 1.07 0 10-2.14 0v3.363c0 .591.479 1.07 1.07 1.07zm4.059 1.393c.274 0 .548-.104.757-.313l2.378-2.378a1.07 1.07 0 10-1.513-1.513L13.886 5.07a1.07 1.07 0 00.757 1.826z" />
    </svg>
  );
};

const MemoSvgShiningArrow = React.memo(SvgShiningArrow);
export default MemoSvgShiningArrow;
