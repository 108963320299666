import * as React from 'react';

const SvgUploadFile = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 27 25"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g
        stroke="#0495FF"
        strokeWidth={2}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M1 15.659v5.082a3 3 0 003 3h19a3 3 0 003-3v-5.082h0M7.415 8.098L13.402 1l6.326 7.098M13.5 1.241V19.35" />
      </g>
    </svg>
  );
};

const MemoSvgUploadFile = React.memo(SvgUploadFile);
export default MemoSvgUploadFile;
