import * as React from 'react';

const SvgSettings = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M14.479 17.197l-.278 1.988a.36.36 0 01-.292.309l-.067.006h-2.93a.36.36 0 01-.345-.25l-.014-.065-.279-1.988a5.249 5.249 0 01-1.045-.589l-.193-.146-1.823.75a.309.309 0 01-.081.02l-.044.003a.367.367 0 01-.285-.132l-.037-.056L5.3 14.452a.39.39 0 01.04-.433l.048-.046 1.545-1.238A6.088 6.088 0 016.883 12c0-.165.01-.33.025-.493l.026-.242-1.545-1.238a.376.376 0 01-.117-.416l.029-.064 1.465-2.595c.055-.1.15-.163.253-.181l.062-.006.066.006.066.016 1.823.75a5.818 5.818 0 011.018-.635l.22-.1.279-1.987a.36.36 0 01.292-.309l.067-.006h2.93a.36.36 0 01.344.25l.015.065.278 1.987c.372.157.72.355 1.045.59l.193.145 1.824-.75a.309.309 0 01.08-.02l.044-.002c.11 0 .215.047.285.132l.037.056 1.465 2.595a.39.39 0 01-.04.433l-.047.047-1.546 1.237c.03.24.051.48.051.735 0 .17-.01.333-.025.494l-.026.241 1.546 1.238a.38.38 0 01.115.416l-.028.064-1.465 2.595a.362.362 0 01-.253.181l-.061.006-.066-.006-.066-.017-1.824-.75a6.06 6.06 0 01-.803.527l-.215.11-.22.098zM12.882 6h-1.018l-.147 1.012-.117.848-.776.322a4.01 4.01 0 00-.729.411l-.187.137-.66.51-.76-.315-.931-.383-.513.908.791.63.652.525-.102.847-.023.247c-.009.113-.014.211-.014.301s.005.188.014.302l.023.253.102.847-.652.525-.79.63.512.908.93-.383.777-.322.666.525c.235.18.469.326.715.447l.186.085.777.323.256 1.86h1.025l.147-1.013.117-.847.776-.323c.24-.102.481-.237.729-.41l.187-.137.66-.51 1.691.697.513-.907-.791-.63-.652-.525.103-.848.022-.244a3.747 3.747 0 00-.007-.72l-.118-.978.652-.525.784-.638-.513-.907-.93.382-.777.323-.666-.525a4.204 4.204 0 00-.715-.447l-.186-.086-.777-.322L12.882 6zm-.505 3c1.618 0 2.93 1.342 2.93 3 0 1.657-1.312 3-2.93 3-1.62 0-2.93-1.343-2.93-3 0-1.658 1.31-3 2.93-3zm0 1.5c-.806 0-1.465.675-1.465 1.5s.659 1.5 1.465 1.5c.805 0 1.465-.675 1.465-1.5s-.66-1.5-1.465-1.5z"
        fill="#CCC"
        fillRule="nonzero"
      />
    </svg>
  );
};

const MemoSvgSettings = React.memo(SvgSettings);
export default MemoSvgSettings;
