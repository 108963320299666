import * as React from 'react';

const SvgCrossoverhover = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g transform="translate(7 9)" fill="none" fillRule="evenodd">
        <path
          d="M0 19.743l.395-4.404a2.4 2.4 0 01.813-1.594l1.208-1.053a2.4 2.4 0 01.02-.017l2.053-1.753a2.4 2.4 0 011.281-.558l8-.93a2.4 2.4 0 011.383.255l1.192.62a2.4 2.4 0 001.108.271h22.189a2.4 2.4 0 00.928-.187l2.848-1.195a2.375 2.375 0 013.293 2.19v7.427a.928.928 0 01-.928.928H0z"
          fill="#FFAB00"
        />
        <ellipse fill="#FFF" cx={38.499} cy={18.954} rx={3.66} ry={3.641} />
        <ellipse fill="#FFF" cx={8.466} cy={18.954} rx={3.66} ry={3.641} />
        <g stroke="#000" strokeWidth={1.2}>
          <path
            d="M31.593 19.743H16.97m-12.03 0H1.202A1.208 1.208 0 010 18.53v-2.444c0-1.544.977-2.914 2.426-3.403a3.865 3.865 0 013.407-2.827l7.303-.583 5.477-4.805a7.154 7.154 0 013.881-1.145h13.908c1.527 0 4.118-.348 6.525 1.532 1.604 1.254 2.76 2.992 3.47 5.215l.594 7.04c.113 1.335-.867 2.51-2.19 2.624a2.378 2.378 0 01-.204.01h-2.196"
            strokeLinecap="round"
          />
          <path
            strokeLinecap="square"
            d="M24.844 1.003v1.214M36.857 1.003v1.214"
          />
          <path strokeLinecap="round" d="M22.92.607h16.003" />
          <path d="M28.57 3.323l-1.35 6.747v9.673" />
          <path d="M13.299 9.35l1.296.888c.2.137.436.21.678.21H39.81a1.2 1.2 0 00.288-.035l.65-.16h0l4.763-1.66" />
          <path strokeLinecap="square" d="M38.304 4.245l1.239 5.654" />
          <path d="M1.447 13.102l5.346-.722a2.4 2.4 0 002.065-2.641l-.03-.274h0" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21.982 14.379h2.403M30.392 14.379h2.402"
          />
          <ellipse
            strokeLinejoin="round"
            cx={8.828}
            cy={19.359}
            rx={3.66}
            ry={3.641}
          />
          <ellipse
            strokeLinejoin="round"
            cx={38.304}
            cy={19.359}
            rx={3.66}
            ry={3.641}
          />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgCrossoverhover = React.memo(SvgCrossoverhover);
export default MemoSvgCrossoverhover;
