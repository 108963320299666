import * as React from 'react';

const SvgSport = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 60 40"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M6.016 24.88c.556-.243 1.436-1.869 2.64-4.876l12.005-2.96L45.591 16 54 17.963v5.528L48.092 26c-28.607-.502-42.632-.875-42.076-1.12z"
          fill="#FFAB00"
        />
        <g transform="translate(6 12)">
          <ellipse fill="#FFF" cx={10.793} cy={12.052} rx={3.635} ry={3.616} />
          <ellipse fill="#FFF" cx={38.233} cy={12.052} rx={3.635} ry={3.616} />
          <g stroke="#000" strokeLinejoin="round" strokeWidth={1.2}>
            <path
              d="M32.091 14.004c-9.29-.158-12.453-.002-17.44-.093m-7.548 0C2.44 13.812.57 13.525 0 13.478c0-.925.514-2.319 1.543-4.18a3.591 3.591 0 011.778-1.587c4.286-1.758 8.66-2.826 13.12-3.205 4.857-.412 12.884-.51 24.082-.294l6.754 1.685.711 5.044c.072.51-.185 1.01-.639 1.244l-1.725.758"
              strokeLinecap="round"
            />
            <path d="M18.093 13.355L16.69 8.197l1.232-3.985L25.674 0c5.96 0 10.906 1.404 14.838 4.212" />
            <path strokeLinecap="round" d="M46.421 5.124v-3.44h-1.595" />
            <path d="M32.002.966l1.193 3.616" />
            <ellipse cx={11.015} cy={12.384} rx={3.635} ry={3.616} />
            <ellipse cx={38.455} cy={12.384} rx={3.635} ry={3.616} />
            <path
              strokeLinecap="round"
              d="M27.23 8.197h4.772M27.23 10.608h4.772"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

const MemoSvgSport = React.memo(SvgSport);
export default MemoSvgSport;
