import * as React from 'react';

const SvgIndustry = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M336 688h146v-54H336v54zm-34 35V599h214v124H302zm474 3v135h86V163h-86v357H650l-1-137-173 158-1-158-173 157V384L164 510l-2 351h488V726h126zm-34 170V761h-58v135H128l2-401 206-188v156l173-157v158l174-158 1 179h58V128h154v768H742z" />
    </svg>
  );
};

const MemoSvgIndustry = React.memo(SvgIndustry);
export default MemoSvgIndustry;
