import * as React from 'react';

const SvgDone = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 32 32"
      {...props}
    >
      <path d="M11.777 25.297l-7.086-7.2c-.922-.937-.922-2.457 0-3.394s2.418-.937 3.34 0l5.416 5.537 11.32-11.537c.922-.937 2.418-.937 3.34 0s.922 2.457 0 3.394l-12.99 13.2a2.336 2.336 0 01-3.34 0z" />
    </svg>
  );
};

const MemoSvgDone = React.memo(SvgDone);
export default MemoSvgDone;
