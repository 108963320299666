import * as React from 'react';

const SvgCar = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox="0 0 1024 1024"
      {...props}
    >
      <path d="M744 441L572 288H312l-146 93H64v279h72q12 34 41 55t65 21q41 0 72.5-27.5T352 640h316q6 41 37 68.5t73 27.5q36 0 65-21t40-55h77V441H744zM559 323l133 118H424V323h135zm-169 0v118H188v-33l135-85h67zm388 378q-32 0-55-23t-23-55 23-55 55-23 54.5 23 22.5 55-23 55-54 23zM242 545q32 0 54.5 23t22.5 55-22.5 55-54.5 23-54.5-23-22.5-55 22.5-55 54.5-23zm536-35q-42 0-73 27.5T667 606H352q-6-41-37.5-68.5T242 510q-46 0-79 33t-33 80v2H99V416h54v60h772v149h-36v-2q0-47-32.5-80T778 510z" />
    </svg>
  );
};

const MemoSvgCar = React.memo(SvgCar);
export default MemoSvgCar;
