import * as React from 'react';

const SvgPedestrianSafety = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 31"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <image
        transform="matrix(-1 0 0 1 117 -2115)"
        x={87}
        y={2112}
        width={35}
        height={37}
        xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADYAAAA2CAYAAACMRWrdAAAAAXNSR0IArs4c6QAAAERlWElmTU0AKgAAAAgAAYdpAAQAAAABAAAAGgAAAAAAA6ABAAMAAAABAAEAAKACAAQAAAABAAAANqADAAQAAAABAAAANgAAAADzQy6kAAADWUlEQVRoBe1ZgVEcMQy8z6QBWqAFKIGUQAukBFqgBSiBlAAlkBKghFDCZ9dYHp2R/OYRx3nmNHP4T5btXdmW5WOaNtk8sHlg88AIHtjv95d4nvFQWF6PgLuJESRIypKxyYHRk8UKun9Nj6y9kgQcYlSfROL/EdlZR18vjs3rbrd7derWr8asXDgzdrV+9AcQZnLca1yWLC8PNBmjGkRO8ZyNgbYDJcic4LnHI8IZu8ETGjQ6oMSZADzPLy8iPsSNtGBPIHSL55CMM2tgwr3kHcg10fAA8iXnGFBfYFE84ekNEr12C661aiiQOsOj95OE9XqW9DudECpfMWM8bGXPMNM470A8xIzJlYQzckpSemoav9dLjkQU8OdMikvTE+2EBxiFkYteihrY37wEtS6rSnFXfk1TCjggt740C6D0mZUS20qH1yLpDoY3Zh+WMOiwP9mvygcL/wQIfW6lmYJOLze8FikZBzRMt0hQR1MxvF+Yxny4DE7AyGzoPSd1Ut7Me3h7QyXTL+43kVvLbjEdUNDrIsnLePG+cdCumW2gnk7hvjtKfh7VymjEGzCA/EIVl6AEhRawdBQYXSUV+uMZ6N24vWbL6EHU21+oSjLezRmw9dLMPMxiLHKgUO8vRjx90dQsxyEH1PpMI4kU3lFe88WQMcgBuD7TyKN87eVvKgxpRsplIkNjFAC29tcsQsLGI/f9mYbHDaDrNMn8hG3YQXX82eXhCdEDmJXNu2kR7N0lGwIoqhMDKFT+dQR19eyWHDIK06f7AUhr35g5oQyGNu+OBalbRQmAzO3q7DxdNlsAczsUM2mmW63+wusAS2fkgnIWCb1BYVw7ZB1hH8Dq5URi3dcN2NZOaS5fz0HhegCrIxtnoPs8gm0dQD79OS76m4c47fcH/5H3KA1z2e2Uql3sKzzOf+jJFeWoZYT2etbHyBt73Zgd1Pqq1dvVtOu2VIYEgFcr4v3BEpTPbqkFbLmsSgKsunmE7WwJwpakrIj4zlb1E/MTg1sREOq9ueEb9mbUZD/szBCLcBipK2NAqhgFzUMV+vpeRnuKeXhDbx30qQH+xO89dFqHZBmMpetN1ElQ0fby23OGtyrYris4de8xdOiG4A+G9q7ls/R4XaA2o80DmweKB/4DsP6RsKAzE2UAAAAASUVORK5CYII="
        fill="none"
        fillRule="evenodd"
      />
    </svg>
  );
};

const MemoSvgPedestrianSafety = React.memo(SvgPedestrianSafety);
export default MemoSvgPedestrianSafety;
