import * as React from 'react';

const SvgFilter = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 14 14"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g stroke="#363636" strokeWidth={1.2} fill="none" fillRule="evenodd">
        <g transform="translate(1.64 8)">
          <path strokeLinecap="round" d="M3.281 2h7.438" />
          <circle fill="#FFF" cx={2} cy={2} r={2} />
        </g>
        <g transform="matrix(-1 0 0 1 12.36 2)">
          <path strokeLinecap="round" d="M3.281 2h7.438" />
          <circle fill="#FFF" cx={2} cy={2} r={2} />
        </g>
      </g>
    </svg>
  );
};

const MemoSvgFilter = React.memo(SvgFilter);
export default MemoSvgFilter;
