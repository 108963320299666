import * as React from 'react';

const SvgCarIndex = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.814 1.819a.75.75 0 10-.628 1.362c1.437.663 2.268 1.38 2.737 2.069H6A1.75 1.75 0 004.25 7v5.586c0 .464.184.909.513 1.237l7.024 7.024a1.75 1.75 0 002.431.042l6.014-5.613c.739-.69.741-1.86.005-2.553l-7.434-6.997a1.75 1.75 0 00-1.2-.476H9.625c-.531-1.263-1.695-2.455-3.81-3.431zm2.657 4.93H6a.25.25 0 00-.25.25v5.587c0 .066.026.13.073.176l7.024 7.024a.25.25 0 00.348.006l6.013-5.612a.25.25 0 00.001-.365l-7.434-6.997a.25.25 0 00-.172-.068H9.976c.033.507-.015.996-.103 1.45a2 2 0 11-1.513-.096c.102-.434.156-.89.111-1.354z"
        fill="#363636"
      />
    </svg>
  );
};

const MemoSvgCarIndex = React.memo(SvgCarIndex);
export default MemoSvgCarIndex;
